import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Marker } from "react-leaflet";
import L from "leaflet";

const useStyles = makeStyles(() => ({
  icon: {
    backgroundImage: props => `url(${props.marker})`,
    backgroundSize: "contain",
    backgroundPositionX: "center",
    backgroundPositionY: "bottom",
    backgroundRepeat: "no-repeat",
  },
}));

const MapMarker = ({ marker, pos, icon, small, type, disableClick, ...props }) => {
  const classes = useStyles({ marker: icon });
  const history = useHistory();

  const handleClick = () => {
    if (type === "gov" && marker.www) {
      window.location.href = marker.www;
    } else if (type === "incident" || type === "announcement") {
      history.push(`/mapa/${type === "incident" ? "zgloszenie" : "ogloszenie"}/` + marker.id);
    }
  };

  const eventHandlers = !disableClick ? { click: handleClick } : undefined;

  return (
    <Marker
      position={pos}
      icon={L.divIcon({
        className: classes.icon,
        iconSize: small ? [37, 43.5] : [49, 58],
        iconAnchor: small ? [14, 40] : [24.5, 49],
        tooltipAnchor: small ? [14, 40] : [20, -29],
      })}
      eventHandlers={eventHandlers}
    >
      {props.children}
    </Marker>
  );
};

export default MapMarker;
