import { axios } from "../../util/axiosConfig";
import { announcements } from "../urls";

const getAnnouncements = ({ northEast, southWest, activeTypes }) =>
  axios
    .get(announcements.search, {
      params: {
        "northEast.lat": northEast.lat,
        "northEast.lng": northEast.lng,
        "southWest.lat": southWest.lat,
        "southWest.lng": southWest.lng,
        category: activeTypes.filter(v => v !== "GOV").join(","),
      },
    })
    .then(response => response.data);

export default getAnnouncements;
