import React, { useEffect, useState } from "react";
import BackRow from "../components/BackRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LabelWithPin from "../../../../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../../../../components/FilledInput/FilledInput";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AcceptTerms from "../../../../../../components/AcceptTerms/AcceptTerms";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { fields } from "./fields";
import { useDispatch, useSelector } from "react-redux";
import { mapAction } from "../../../../../../redux/modules/mapReducer";

import createIncident from "../../../../../../services/map/createIncident";

const useStyles = makeStyles(() => ({
  header: {
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  formRoot: {
    padding: "35px 30px 0px",
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  inputRoot: {
    marginBottom: "30px",
    width: "100%",
  },
  input: {
    width: "100%",
  },
  button: {
    height: "51px",
    width: "80%",
    borderRadius: "25.5px",
    fontSize: "25px",
    marginBottom: "30px",
  },
}));

const NewIncident = ({ setHeader }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const newMarkerPos = useSelector(state => state.mapReducer.newMarkerPos);
  const newMarkerAddress = useSelector(state => state.mapReducer.newMarkerAddress);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDisabled =
    !values.category ||
    values.address === "" ||
    !values.address ||
    (!isLoggedIn && (!values.agreement || !values.termsAgreement1));

  useEffect(() => {
    if (setHeader) {
      setHeader(renderHeader());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleChange("address", newMarkerAddress);
  }, [newMarkerAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!newMarkerPos) {
      return;
    }

    const tmpValues = { ...values };
    tmpValues.latitude = newMarkerPos.lat;
    tmpValues.longitude = newMarkerPos.lng;
    setValues(tmpValues);
  }, [newMarkerPos]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (key, value) => {
    if (key === "image") {
      setImage(value);
    } else {
      const tmpVal = { ...values };
      tmpVal[key] = value;
      setValues(tmpVal);
    }
  };

  const handleCreate = () => {
    setIsLoading(true);
    createIncident(values, image)
      .then(result => {
        history.push(`/mapa/zgloszenie/${result.id}`);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const goBack = () => {
    history.push("/mapa");
    dispatch(mapAction.clearNewMarker());
  };

  if (!newMarkerPos) {
    goBack();
  }

  const renderHeader = () => (
    <BackRow onGoBack={goBack}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="center">
        <Typography className={classes.header}>Nowe zgłoszenie</Typography>
      </Box>
    </BackRow>
  );

  return (
    <React.Fragment>
      {!setHeader && renderHeader()}
      <Box className={classes.formRoot} data-body-scroll-lock-ignore>
        <form className={classes.form}>
          {Object.keys(fields).map(key => {
            const field = fields[key];
            const Component = field.component;
            if (isLoggedIn && field.notLoggedIn) {
              return null;
            }

            return (
              <Box key={key} className={classes.inputRoot}>
                <LabelWithPin label={field.name} />
                {field.component ? (
                  <Component handleChange={(k, v) => handleChange(k, v)} />
                ) : (
                  <TextField
                    select={field.select}
                    multiline={field.multiline}
                    rows={field.rows}
                    placeholder={field.placeholder}
                    variant="filled"
                    className={classes.input}
                    value={values[key] ? values[key] : ""}
                    onChange={e => handleChange(key, e.target.value)}
                  />
                )}
              </Box>
            );
          })}
          {!isLoggedIn && (
            <Box marginBottom="20px">
              <AcceptTerms handleChange={handleChange} />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleCreate()}
            disabled={isLoading || isDisabled}
          >
            {isLoading ? <CircularProgress /> : "Zgłoś"}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

export default NewIncident;
