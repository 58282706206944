import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BluePinIcon from "../../static/BluePinIcon";
import Refferals from "./components/Referrals/Referrals";
import { makeStyles } from "@material-ui/core/styles";
import map from "../../static/topBanner/map.png";
import County from "./components/County/County";
import { counties } from "./components/County/counties";

const useStyles = makeStyles(theme => ({
  outerRoot: {
    height: "calc(100vh - 78px)",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "1000px",
    minHeight: "600px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      height: "unset",
    },
  },
  root: {
    width: "100%",
    flexGrow: "1",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "unset",
    },
  },
  image: {
    position: "relative",
    minWidth: "calc(100% - max(60%, 800px) + 320px)",
    backgroundSize: "cover",
    backgroundPositionX: "right",
    left: "-320px",
    [theme.breakpoints.down("md")]: {
      left: "-320px",
      width: "calc(100% - max(70%, 800px) + 320px)",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mapRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "max(60%, 800px)",
    padding: "0px 320px 55px 50px",
    backgroundImage: `url(${map})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "-2px",
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
      minWidth: "max(70%, 800px)",
      padding: "0px 285px 30px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "100%",
      backgroundPositionX: "left",
      padding: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "37px",
    },
    [theme.breakpoints.down(300)]: {
      padding: "20px",
      height: "fit-content",
    },
  },
  textRoot: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
  },
  icon: {
    width: "40px",
    height: "54px",
    marginRight: "40px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginRight: "24px",
      width: "24px",
      height: "32px",
    },
    [theme.breakpoints.down(300)]: {
      display: "none",
    },
  },
  header: {
    fontWeight: "600",
    whiteSpace: "break-spaces",
    lineHeight: "46px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "30px",
      marginBottom: "15px",
      whiteSpace: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  middleText: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    lineHeight: "38px",
    maxWidth: "570px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  countiesHeader: {
    fontSize: "20px",
    letterSpacing: "2px",
    textAlign: "center",
    marginBottom: "20px",
    textTransform: "uppercase",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  countiesRoot: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TopBanner = ({ src, topText, middleText }) => {
  const classes = useStyles({ src });

  return (
    <Box className={classes.outerRoot}>
      <Box className={classes.root}>
        <Box className={classes.mapRoot}>
          <Box className={classes.textRoot}>
            <Box display="flex">
              <BluePinIcon className={classes.icon} />
              <Box>
                <Typography variant="h1" component="h2" className={classes.header}>
                  {topText}
                </Typography>
                <Typography className={classes.middleText}>{middleText}</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.countiesHeader}>
              Zobacz co zyskały miasta które już z nami są
            </Typography>
            <Box className={classes.countiesRoot}>
              {counties.map(county => (
                <County src={county.src} name={county.name} key={county.name} />
              ))}
            </Box>
          </Box>
        </Box>
        <div style={{ backgroundImage: `url(${src})` }} className={classes.image} />
      </Box>
      <Refferals />
    </Box>
  );
};

export default TopBanner;
