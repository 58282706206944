import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./screens/App";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { plPL } from "@material-ui/core/locale";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

const theme = createMuiTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        mobile: 480,
        small: 600,
        sm: 600,
        md: 960,
        lg: 1440,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: `"Poppins", "Roboto", "sans-serif"`,
    },
    palette: {
      primary: {
        main: "#076FFF",
      },
      secondary: {
        main: "#085289",
      },
      text: {
        primary: "#191919",
        secondary: "#6E6E6E",
      },
      common: {
        darkPink: "#DB0F75",
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: "30px",
          fontWeight: "500",
          letterSpacing: "3px",
          lineHeight: "37px",
        },
        h2: {
          fontSize: "35px",
          fontWeight: "600",
          letterSpacing: "3.5px",
          lineHeight: "53px",
        },
        h3: {
          fontSize: "20px",
          fontWeight: "500",
          letterSpacing: "2px",
          lineHeight: "24px",
        },
        body1: {
          fontSize: "18px",
          fontWeight: "400",
        },
      },
      MuiButton: {
        contained: {
          boxShadow: "0px 3px 6px #0000004D",
          height: "38px",
          borderRadius: "19px",
          padding: "8px 30px",
          fontSize: "18px",
        },
        containedSizeLarge: {
          padding: "19px 45px",
          boxShadow: "0px 6px 6px #0000004D",
          fontSize: "25px",
          letterSpacing: "2.5px",
          height: "70px",
          borderRadius: "35px",
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "#F7F7F7",
          height: "41px",
          borderRadius: "20.5px !important",
          border: "1px solid #DDDDDD",
          fontWeight: "500",
        },
        input: {
          padding: "0px 21px",
          fontSize: "15px",
          "&::placeholder": {
            color: "#6E6E6E",
            opacity: "1",
          },
          "&:-webkit-autofill": {
            height: "100%",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          },
        },
        underline: {
          "&::after": {
            display: "none",
          },
          "&::before": {
            display: "none",
          },
        },
      },
    },
  },
  plPL
);

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const Wrapper = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById("root"));
