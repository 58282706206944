import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as PinBlue } from "../../../static/pin-blue.svg";
import { ReactComponent as PinColor } from "../../../static/pin-color.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const fields = [
  <React.Fragment>
    Na podany adres e-mail wysłaliśmy maila z <b>linkiem aktywacyjnym.</b>
  </React.Fragment>,
  <React.Fragment>
    Gdy otrzymasz wiadomość <b>kliknij na link</b> aby dokończyć rejestrację.
  </React.Fragment>,
  <React.Fragment>
    <b>UWAGA!</b> Jeśli ważność linku wygasa w przeciągu <b>24 godzin</b>, więc jeśli nie dokończysz
    rejestracji w tym czasie, proces zakładania konta trzeba będzie zacząć <b>od początku.</b>
  </React.Fragment>,
];

const useStyles = makeStyles(theme => ({
  textRoot: {
    display: "flex",
    alignItems: "flex-start",
    "&:not(:last-of-type)": {
      marginBottom: "20px",
    },
  },
  iconRoot: {
    width: "42px",
    display: "flex",
    justifyContent: "center",
    marginRight: "10px",
  },
  largeIcon: {
    fontSize: "44px",
    position: "relative",
    top: "-3px",
    color: theme.palette.primary.main,
  },
  icon: {
    width: "22px",
    height: "30px",
  },
  header: {
    fontSize: "40px",
    letterSpacing: "4px",
    lineHeight: "49px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "420px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      width: "unset",
      maxWidth: "300px",
      lineHeight: "25px",
    },
  },
  center: {
    alignItems: "center",
  },
  extraMargin: {
    marginBottom: "30px",
  },
  text: {
    fontSize: "20px",
    letterSpacing: "2px",
    lineHeight: "24px",
    width: "420px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
      width: "unset",
      maxWidth: "450px",
    },
  },
  dividerRoot: {
    display: "flex",
    alignItems: "center",
    width: "500px",
    marginBottom: "35px",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  divider: {
    flexGrow: "1",
    flexBasis: "10px",
    backgroundColor: theme.palette.text.secondary,
  },
  dividerText: {
    fontSize: "20px",
    letterSpacing: "2px",
    color: theme.palette.text.secondary,
    margin: "0 20px",
    textAlign: "center",
  },
}));

const Success = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <React.Fragment>
      <Box className={`${classes.textRoot} ${classes.extraMargin} ${classes.center}`}>
        <Box className={classes.iconRoot}>
          <CheckCircleIcon className={classes.largeIcon} />
        </Box>
        <Typography component="h1" variant="h1" className={classes.header}>
          Dziękujemy za rejestrację
        </Typography>
      </Box>
      {fields.map((field, index) => (
        <Box
          className={`${classes.textRoot} ${
            index === fields.length - 1 ? classes.extraMargin : ""
          }`}
          key={index}
        >
          <Box className={classes.iconRoot}>
            {index % 2 ? (
              <PinBlue className={classes.icon} />
            ) : (
              <PinColor className={classes.icon} />
            )}
          </Box>
          <Typography className={classes.text}>{field}</Typography>
        </Box>
      ))}
      <Box className={classes.dividerRoot}>
        <Divider className={classes.divider} />
        <Typography className={classes.dividerText}>
          {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}
        </Typography>
        <Divider className={classes.divider} />
      </Box>
    </React.Fragment>
  );
};

export default Success;
