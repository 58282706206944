export const fields = [
  {
    id: "email",
    type: "email",
    placeholder: "E-mail",
  },
  {
    id: "nick",
    placeholder: "Podpis",
  },
  {
    id: "password",
    autocomplete: "new-password",
    type: "password",
    placeholder: "Hasło",
  },
  {
    id: "repeatPassword",
    autocomplete: "new-password",
    type: "password",
    placeholder: "Powtórz hasło",
  },
];
