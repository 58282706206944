const incidentsUrl = "/incidents";
export const incidents = {
  search: incidentsUrl + "/search",
  single: incidentsUrl,
  categories: incidentsUrl + "/categories",
  create: incidentsUrl + "/create",
};

const announcementsUrl = "/announcements";
export const announcements = {
  search: announcementsUrl + "/search",
  single: announcementsUrl,
};

const accountUrl = "/account";
export const account = {
  login: accountUrl + "/login",
  details: accountUrl,
  nickExists: accountUrl + "/isNickExists",
  create: accountUrl + "/create",
};

const imagesUrl = process.env.REACT_APP_BASE_URL + "/image";
export const images = {
  avatar: imagesUrl + "/avatar",
  incident: imagesUrl + "/incident",
  announcement: imagesUrl + "/announcement",
};
