import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import BluePinIcon from "../../../../static/BluePinIcon";
import mobileBg from "../../../../static/mobile-bg.png";
import notifications from "../../../../static/push-notif.png";
import surveys from "../../../../static/survey.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: "calc(100vh - 78px)",
    display: "flex",
    alignItems: "center",
    backgroundImage: `url(${mobileBg})`,
    backgroundColor: "#14387B",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "0px min(100px, 5%)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      backgroundPosition: "right",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      padding: "36px",
    },
  },
  leftRoot: {
    display: "flex",
    width: "55%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "40px",
    },
  },
  icon: {
    height: "45px",
    width: "33px",
    color: "#FFFFFF",
    marginRight: "13px",
  },
  header: {
    marginBottom: "52px",
    fontWeight: "bold",
    color: "#FFFFFF",
    maxWidth: "880px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  subtext: {
    fontSize: "30px",
    letterSpacing: "3px",
    lineHeight: "46px",
    fontWeight: "500",
    color: "#FFFFFF",
    "&:not(:last-of-type)": {
      marginBottom: "19px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
      marginBottom: "0px !important",
    },
  },
  rightRoot: {
    width: "45%",
    display: "flex",
    height: "580px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "unset",
      flexGrow: "1",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  imageRound: {
    width: "483px",
    height: "483px",
    borderRadius: "50%",
  },
  image: {
    alignSelf: "flex-end",
    width: "572px",
    height: "335px",
    position: "absolute",
    right: "0",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      alignSelf: "unset",
      maxWidth: "572px",
      width: "100%",
      height: "unset",
    },
  },
}));

const MobileApp = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.leftRoot}>
          <BluePinIcon fill="#14387B" className={classes.icon} />
          <Box>
            <Typography component="h2" variant="h2" className={classes.header}>
              Najlepsze aplikacja dla mieszkańca, Nowoczesna promocja dla urzędu
            </Typography>
            <Typography className={classes.subtext}>Testuj dwa miesiące ZA DARMO,</Typography>
            <Typography className={classes.subtext}>Bez kosztów inwestycyjnych,</Typography>
            <Typography className={classes.subtext}>
              A my uruchomimy system już w 1 dzień!
            </Typography>
          </Box>
        </Box>
        <Box className={classes.rightRoot}>
          <Hidden smDown>
            <img src={notifications} alt="" className={classes.imageRound} />
          </Hidden>
          <img src={surveys} alt="" className={classes.image} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default MobileApp;
