import React from "react";
import Box from "@material-ui/core/Box";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: "1",
    minHeight: "calc(100vh - 78px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "0",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
    },
  },
}));

const withLayout = WrappedComponent => {
  const HocComponent = ({ ...props }) => {
    const classes = useStyles();
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box className={classes.root}>
          <WrappedComponent {...props} />
        </Box>
        <Footer />
      </Box>
    );
  };
  return HocComponent;
};

export default withLayout;
