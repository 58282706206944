import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BluePinIcon from "../../../../static/BluePinIcon";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../../../static/partnership-bg.png";
import { texts } from "./texts";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "80px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundPositionY: "bottom",
    minHeight: "calc(100vh - 78px)",
    width: "calc(100% - 20px)",
    padding: "0px min(10%, 190px)",
    margin: "0px 20px 16px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      padding: "36px 16px 36px 36px",
    },
  },
  iconRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      height: "32px",
      width: "24px",
    },
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  textRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: "1",
    padding: "60px 0px 100px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    }
  },
  text: {
    fontSize: "30px",
    letterSpacing: "3px",
    fontWeight: "500",
    lineHeight: "36px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
}));

const Partnership = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.iconRoot}>
        <BluePinIcon className={classes.icon} />
        <Typography component="h2" variant="h2" className={classes.header}>
          Jak to działa?
        </Typography>
      </Box>
      <Box className={classes.textRoot}>
        {texts.map((text, index) => (
          <Typography
            key={index}
            style={text.style}
            className={classes.text}
            color={index % 2 ? "primary" : "textPrimary"}
          >
            {text.text}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Partnership;
