export const fields = [
  {
    id: "email",
    type: "email",
    placeholder: "E-mail",
  },
  {
    id: "password",
    type: "password",
    placeholder: "Hasło",
  },
];
