import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FilterBar from "./components/FilterBar";
import List from "./components/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { markers } from "../../../../../static/map/markers";
import { mapAction } from "../../../../../redux/modules/mapReducer";

const IncidentsList = ({ showCollapse }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const incidents = useSelector(state => state.mapReducer.incidents);
  const activeStatuses = useSelector(state => state.mapReducer.activeStatuses);

  const Wrapper = isMobile ? Collapse : React.Fragment;

  return (
    <React.Fragment>
      <Wrapper in={isMobile ? showCollapse : undefined}>
        <FilterBar
          types={[1, 2, 3, 4]}
          activeIcons={markers}
          active={activeStatuses}
          changeActive={mapAction.changeActiveStatuses}
          searchAction={mapAction.searchIncidents}
          placeholder="SZUKAJ ZGŁOSZEŃ"
        />
      </Wrapper>
      <List markers={incidents} isIncident />
    </React.Fragment>
  );
};

export default IncidentsList;
