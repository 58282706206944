import React from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(theme => ({
  checkbox: {
    height: "24px",
    width: "24px",
    margin: "0 20px 0 10px",
    position: "relative",
    top: "-5.5px",
  },
  checkboxRoot: {
    display: "flex",
    maxWidth: "510px",
    alignItems: "flex-start",
    "&:not(:last-of-type)": {
      marginBottom: "10px",
    },
  },
  bottomText: {
    fontSize: "11px",
    letterSpacing: "1.1px",
    lineHeight: "14px",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "500",
    textDecoration: "none",
  },
}));

const AcceptTerms = ({ handleChange }) => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <React.Fragment>
      <Box className={classes.checkboxRoot}>
        <Checkbox
          color="primary"
          checkedIcon={<CheckCircleIcon />}
          icon={<RadioButtonUncheckedIcon />}
          className={classes.checkbox}
          onChange={(e, check) => handleChange("termsAgreement1", check)}
          disableRipple
        />
        <Typography color="primary" className={classes.bottomText}>
          Znam i akceptuję{" "}
          <Link className={classes.link} to="/regulamin" target="_blank">
            Regulamin
          </Link>{" "}
          serwisu {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}*
        </Typography>
      </Box>
      <Box className={classes.checkboxRoot}>
        <Checkbox
          color="primary"
          checkedIcon={<CheckCircleIcon />}
          icon={<RadioButtonUncheckedIcon />}
          className={classes.checkbox}
          onChange={(e, check) => handleChange("agreement", check)}
          disableRipple
        />
        <Typography className={classes.bottomText}>
          Wyrażam zgodę na przetwarzanie danych osobowych przez Xentivo Sp. z o. o. z siedzibą w
          Warszawie oraz podmioty publiczne i niepubliczne, którym dane te zostaną udostępnione, w
          celu obsługi zgłoszeń oraz dla wypełnienia celów działania serwisu{" "}
          {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} na zasadach opisanych w{" "}
          <Link className={classes.link} to="/regulamin" target="_blank">
            Regulaminie*
          </Link>
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AcceptTerms;
