import { axios } from "../../util/axiosConfig";
import { incidents } from "../urls";

const createIncident = (values, image) => {
  const formData = new FormData();
  formData.append("incident", JSON.stringify(values));
  if (image) {
    formData.append("file", image, image.name);
  }

  return axios
    .post(incidents.create, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data.results[0]);
};

export default createIncident;
