export const townData = {
  plock: {
    name: "Płock",
    address: "Stary Rynek 1, 09-400 Płock",
    www: "www.plock.eu",
    email: "info@plock.eu",
    phone: "+48 24 367 14 02",
    id: 45,
    airStation: 501,
    airStationAddress: "ul. Mikołaja Reja 28",
    openingHours: [
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "8:30",
        to: "17:30",
      },
      {
        from: "8:30",
        to: "17:30",
      },
    ],
    geoJSON: require("./maps/plock.json"),
    logo: require("./logos/plock.jpg").default,
  },
  piastow: {
    name: "Piastów",
    address: "ul. 11 Listopada 2, 05-820 Piastów",
    www: "www.piastów.pl",
    email: "umpiastow@piastow.pl",
    tel: "+48 22 77 05 200",
    id: 52,
    airStation: 488,
    airStationAddress: "ul. Pułaskiego 6/8",
    openingHours: [
      {
        from: "9:00",
        to: "18:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "15:00",
      },
    ],
    geoJSON: require("./maps/piastow.json"),
    logo: require("./logos/piastow.svg").default,
  },
  nieporet: {
    gmina: true,
    name: "Nieporęt",
    address: "Plac Wolności 1, 05-126 Nieporęt",
    www: "www.nieporet.pl",
    email: "urzad@nieporet.pl",
    tel: "+48 22 767 04 00",
    id: 59,
    airStation: 471,
    airStationAddress: "ul. Zegrzyńska 38, Legionowo",
    openingHours: [
      {
        from: "8:00",
        to: "18:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
    ],
    geoJSON: require("./maps/nieporet.json"),
    logo: require("./logos/nieporet.png").default,
  },
  ostroleka: {
    name: "Ostrołęka",
    address: "Plac gen. Józefa Bema 1, 07-400 Ostrołęka",
    www: "www.ostroleka.pl",
    email: "um@um.ostroleka.pl",
    tel: "+48 29 764 68 11",
    id: 57,
    airStation: 618,
    airStationAddress: "ul. Sikorskiego 48/94, Łomża",
    openingHours: [
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "17:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
    ],
    geoJSON: require("./maps/ostroleka.json"),
    logo: require("./logos/ostroleka.jpg").default,
  },
  srem: {
    name: "Śrem",
    address: "Plac 20. Października 1, 63-100 Śrem",
    www: "www.srem.pl",
    email: "urzad@srem.pl",
    tel: "+48 61 28 35 225",
    id: 55,
    airStation: 16495,
    airStationAddress: "ul. Czereśniowa, Mosina",
    openingHours: [
      {
        from: "7:00",
        to: "16:00",
      },
      {
        from: "7:00",
        to: "16:00",
      },
      {
        from: "7:00",
        to: "16:00",
      },
      {
        from: "7:00",
        to: "16:00",
      },
      {
        from: "7:00",
        to: "16:00",
      },
    ],
    geoJSON: require("./maps/srem.json"),
    logo: require("./logos/srem.jpg").default,
  },
  zgierz: {
    name: "Zgierz",
    address: "Plac Jana Pawła II 16, 95-100 Zgierz",
    www: "www.miasto.zgierz.pl",
    email: "e-urzad@umz.zgierz.pl",
    tel: "+48 42 716 28 54",
    id: 58,
    airStation: 355,
    airStationAddress: "ul. Mielczarskiego 1",
    openingHours: [
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "18:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
    ],
    geoJSON: require("./maps/zgierz.json"),
    logo: require("./logos/zgierz.jpg").default,
  },
  wrzesnia: {
    name: "Września",
    address: "ul. Ratuszowa 1, 62-300 Września",
    www: "www.wrzesnia.pl",
    email: "wrzesnia@wrzesnia.pl",
    tel: "+48 61 640 40 40",
    id: 60,
    airStation: 729,
    airStationAddress: "ul. Polanka, Poznań",
    openingHours: [
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "7:30",
        to: "15:00",
      },
      {
        from: "7:30",
        to: "15:00",
      },
      {
        from: "7:30",
        to: "15:00",
      },
      {
        from: "7:30",
        to: "15:00",
      },
    ],
    geoJSON: require("./maps/wrzesnia.json"),
    logo: require("./logos/wrzesnia.png").default,
  },
  miescisko: {
    gmina: true,
    name: "Mieścisko",
    address: "Pl. Powstańców Wlkp. 13, 62 – 290 Mieścisko",
    www: "www.miescisko.nowoczesnagmina.pl",
    email: "ug@miescisko.nowoczesnagmina.pl",
    tel: "+48 61 42 98 010",
    id: 66,
    airStation: 10834,
    airStationAddress: "Os. Leśne 22, Kozie Głowy",
    openingHours: [
      {
        from: "8:00",
        to: "17:00",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "14:30",
      },
    ],
    geoJSON: require("./maps/miescisko.json"),
    logo: require("./logos/miescisko.jpg").default,
  },
  rawamazowiecka: {
    name: "Rawa Mazowiecka",
    address: "Pl. Marszałka Józefa Piłsudskiego 5, 96-200 Rawa Mazowiecka",
    www: "www.rawamazowiecka.pl",
    email: "um@rawamazowiecka.pl",
    tel: "+48 46 814 47 11",
    id: 67,
    airStation: 562,
    airStationAddress: "ul. Roosevelta 2, Żyrardów",
    openingHours: [
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "17:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "15:00",
      },
    ],
    geoJSON: require("./maps/rawa.json"),
    logo: require("./logos/rawa.jpg").default,
  },
  slupca: {
    name: "Słupca",
    address: "ul. Pułaskiego 21, 62-400 Słupca",
    www: "www.miasto.slupca.pl",
    email: "um@miasto.slupca.pl",
    tel: "+48 63 277 27 27",
    id: 63,
    airStation: 902,
    airStationAddress: "ul. Wyszyńskiego 3, Konin",
    openingHours: [
      {
        from: "7:30",
        to: "16:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
    ],
    geoJSON: require("./maps/slupca.json"),
    logo: require("./logos/slupca.jpg").default,
  },
  wagrowiec: {
    name: "Wągrowiec",
    address: "ul. Kościuszki 15a, 62-100 Wągrowiec",
    www: "www.wagrowiec.eu",
    email: "miasto@wagrowiec.eu",
    tel: "+48 67 262 03 46",
    id: 65,
    airStation: 10834,
    airStationAddress: "Os. Leśne 22, Kozie Głowy",
    openingHours: [
      {
        from: "8:00",
        to: "17:00",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "15:30",
      },
      {
        from: "7:30",
        to: "14:30",
      },
    ],
    geoJSON: require("./maps/wagrowiec.json"),
    logo: require("./logos/wagrowiec.png").default,
  },
  wisniew: {
    gmina: true,
    name: "Wiśniew",
    address: "ul. Siedlecka 13, 08-112 Wiśniew",
    www: "www.wisniew.pl",
    email: "ug@wisniew.pl",
    tel: "+48 25 641 73 13",
    id: 61,
    airStation: 517,
    airStationAddress: "ul. Konarskiego 11, Siedlce",
    openingHours: [
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
      {
        from: "8:00",
        to: "16:00",
      },
    ],
    geoJSON: require("./maps/wisniew.json"),
    logo: require("./logos/wisniew.png").default,
  },
};
