import React, { useEffect } from "react";
import withLayout from "../components/withLayout/withLayout";
import LandingPage from "./LandingPage/LandingPage";
import TermsOfUsePage from "../screens/TermsOfUsePage/TermsOfUsePage";
import ForOfficesPage from "../screens/ForOfficesPage/ForOfficesPage";
import LoginPage from "./Login/Login/LoginPage";
import RegisterWrapper from "./Login/Register/RegisterWrapper";
import MapPage from "./MapPage/MapPage";
import TownPage from "./TownPage/TownPage";

import { useSelector, useDispatch } from "react-redux";
import { userAction } from "../redux/modules/userReducer";
import { Switch, Route } from "react-router-dom";

const App = () => {
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn && window.localStorage.getItem("apiKey")) {
      dispatch(userAction.getUserDetails());
    }

    if (isLocalSpot) {
      document.title = "LocalSpot";
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route path="/regulamin">
        <TermsOfUsePage />
      </Route>
      <Route path="/dla-urzedow">
        <ForOfficesPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/rejestracja">
        <RegisterWrapper />
      </Route>
      <Route path="/mapa">
        <MapPage />
      </Route>
      <Route path="/city/:city">
        <TownPage />
      </Route>
      <Route path="/">
        <LandingPage />
      </Route>
    </Switch>
  );
};

export default withLayout(App);
