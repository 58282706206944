import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BluePinIcon from "../../static/BluePinIcon";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: props => (props.marginBottom ? props.marginBottom : "12px"),
  },
  icon: {
    height: "21px",
    width: "16px",
    color: theme.palette.primary.main,
    marginRight: props => (props.marginRight ? props.marginRight : "10px"),
  },
  label: {
    fontSize: props => (props.size ? props.size + "px" : "15px"),
    fontWeight: props => (props.fontWeight ? props.fontWeight : "500"),
    letterSpacing: props => (props.size ? props.size / 10 + "px" : "1.5px"),
    textTransform: props => (props.textTransform ? props.textTransform : "uppercase"),
  },
}));

const LabelWithPin = ({ label, marginBottom, marginRight, ...props }) => {
  const classes = useStyles({ marginBottom, marginRight, ...props });
  return (
    <Box className={classes.labelRoot}>
      <BluePinIcon className={classes.icon} />
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default LabelWithPin;
