import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import { ButtonGroup, Button } from "./components/Tabs";
import AddIcon from "@material-ui/icons/Add";
import NewIncident from "./components/NewIncident/NewIncident";
import AnnouncementsList from "./components/AnnouncementsList";
import IncidentsList from "./components/IncidentsList";
import ActiveMarker from "./components/ActiveMarker";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../../../redux/modules/mapReducer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  buttonRoot: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: "90%",
    minHeight: "36px",
    height: "unset",
    fontSize: "15px",
    letterSpacing: "0.3px",
  },
  sidebarRoot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px 16px 0 0",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    height: "calc(100vh - 178px)",
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const center = useSelector(state => state.mapReducer.center);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const addNewIncident = () => {
    dispatch(mapAction.setNewMarker({ pos: center }));
    history.push("/mapa/dodaj-zgloszenie");
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.buttonRoot}>
        <MuiButton
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => addNewIncident()}
        >
          Aby dodać zgłoszenie kliknij tu lub na mapie
        </MuiButton>
      </Box>
      <Box className={classes.sidebarRoot}>
        <Switch>
          <Route path="/mapa/dodaj-zgloszenie">
            <NewIncident />
          </Route>
          <Route path="/mapa/:type/:id">
            <ActiveMarker />
          </Route>
          <Route>
            <ButtonGroup variant="text" color="primary">
              <Button color={value === 0 ? "primary" : "inherit"} onClick={() => setValue(0)}>
                Ogłoszenia
              </Button>
              <Button color={value === 1 ? "primary" : "inherit"} onClick={() => setValue(1)}>
                Zgłoszenia
              </Button>
            </ButtonGroup>
            {value === 0 && <AnnouncementsList />}
            {value === 1 && <IncidentsList />}
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default Sidebar;
