import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LabelWithPin from "../../../../components/LabelWithPin/LabelWithPin";
import { makeStyles } from "@material-ui/core/styles";

const colors = {
  0: "#009F67",
  1: "#8FD134",
  2: "#FFCA08",
  3: "#FF9E2E",
  4: "#DE2F22",
  5: "#BA0D00",
  [-1]: "#B4B2B4",
};

const useStyles = makeStyles(() => ({
  mainText: {
    marginBottom: "15px",
    fontSize: "18px",
    letterSpacing: "1.8px",
    lineHeight: "25px",
  },
  span: {
    color: props => props.color,
    textTransform: "uppercase",
    fontWeight: "600",
  },
}));

const AirStation = ({ station, address }) => {
  const classes = useStyles({ color: colors[station.stIndexLevel?.id] });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box>
      <LabelWithPin
        label={address}
        marginRight="8px"
        marginBottom="15px"
        textTransform="unset"
        fontWeight="400"
        size={isMobile ? 16 : 18}
      />
      <Typography className={classes.mainText}>
        <span className={classes.span}>{station.stIndexLevel?.indexLevelName}</span>
        {station.stIndexLevel?.id !== -1 && " indeks powietrza"}
      </Typography>
    </Box>
  );
};

export default AirStation;
