import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LoginBox from "./LoginBox";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import kmLogo from "../../../static/km-logo.png";
import lsLogo from "../../../static/ls-logo.png";

const links = [
  {
    name: "Strona główna",
    link: "/",
  },
  {
    name: "Dla urzędów",
    link: "/dla-urzedow",
  },
  {
    name: "Regulamin",
    link: "/regulamin",
  },
  {
    name: "Mapa",
    link: "/mapa",
  },
];

const useStyles = makeStyles(theme => ({
  appbar: {
    boxShadow: "0 3px 6px #0000004E",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
  },
  toolbar: {
    height: "78px",
    minHeight: "unset",
    padding: "0 44px 0px calc(10% - 12px)",
    [theme.breakpoints.down("md")]: {
      paddingRight: "min(30px, 3%)",
      paddingLeft: "min(30px, 3%)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
  image: {
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      flexGrow: "1",
    },
  },
  logo: {
    height: "50px",
    [theme.breakpoints.down(1280)]: {
      width: "160px",
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px",
    },
  },
  links: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
    flexGrow: "1",
    height: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "@media (max-height: 599.95px)": {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexGrow: "1",
      justifyContent: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: "18px",
    textTransform: "uppercase",
    textAlign: "center",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "20px",
      marginRight: "0px",
      marginBottom: "20px",
      "@media (max-height: 599.95px)": {
        flexBasis: "50%",
        textAlign: "center",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px",
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    fontWeight: "500",
  },
  iconButton: {
    position: "relative",
    width: "24px",
    height: "24px",
  },
  icon: {
    position: "absolute",
  },
  collapseRoot: {
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 60px)",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const Header = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  const renderLinks = () => (
    <Box className={classes.links}>
      {links.map(link => (
        <Link
          className={`${classes.link} ${link.link === location.pathname ? classes.activeLink : ""}`}
          to={link.link}
          key={link.link}
          onClick={() => setOpen(false)}
        >
          {link.name}
        </Link>
      ))}
    </Box>
  );

  return (
    <AppBar className={classes.appbar} position="sticky" color="inherit" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={classes.image} onClick={() => setOpen(false)}>
          <img
            className={classes.logo}
            src={isLocalSpot ? lsLogo : kmLogo}
            alt={isLocalSpot ? "Logo LocalSpot" : "Logo Komunikator Miejski"}
          />
        </Link>
        <Hidden smDown>
          {renderLinks()}
          <LoginBox />
        </Hidden>
        <Hidden mdUp>
          <IconButton className={classes.iconButton} color="primary" onClick={() => setOpen(!open)}>
            <Zoom in={open}>
              <CloseIcon className={classes.icon} />
            </Zoom>
            <Zoom in={!open}>
              <MenuIcon className={classes.icon} />
            </Zoom>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp>
        <Collapse in={open}>
          <Box className={classes.collapseRoot}>
            {renderLinks()}
            <LoginBox />
          </Box>
        </Collapse>
      </Hidden>
    </AppBar>
  );
};

export default Header;
