import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import BackRow from "./components/BackRow";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../../../components/FilledInput/FilledInput";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Dropzone from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import CameraIcon from "@material-ui/icons/CameraAlt";
import Comment from "./components/Comment";
import { makeStyles } from "@material-ui/core/styles";
import { markers } from "../../../../../static/map/sidebar/markers";
import { images } from "../../../../../services/urls";
import { mapAction } from "../../../../../redux/modules/mapReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import getIncidentComments from "../../../../../services/map/getIncidentComments";
import newComment from "../../../../../services/map/newComment";
import createIncidentHeart from "../../../../../services/map/createIncidentHeart";
import createAnnouncementHeart from "../../../../../services/map/createAnnouncementHeart";
import removeIncidentHeart from "../../../../../services/map/removeIncidentHeart";
import removeAnnouncementHeart from "../../../../../services/map/removeAnnouncementHeart";
import noPhoto from "../../../../../static/map/sidebar/no-photo-lg.png";
import noPhotoLs from "../../../../../static/map/sidebar/no-photo-ls-lg.png";

const colors = {
  1: "#D72D21",
  2: "#EFA852",
  3: "#418B5C",
  4: "#BABABA",
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "contents",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100% + 20px)",
      position: "relative",
      top: "-20px",
      zIndex: "1",
    },
  },
  titleAndIcon: {
    display: "flex",
    flexGrow: "1",
    padding: "13px 25px 8px 15px",
  },
  titleRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "0",
    flexGrow: "1",
    marginRight: "10px",
  },
  title: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "600",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  address: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  circle: {
    minWidth: "34px",
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    backgroundColor: props => props.color,
    boxShadow: "0px 3px 6px #00000040",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    width: "22px",
    height: "22px",
    filter: "drop-shadow(0 3px 3px #00000040)",
  },
  contentRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  imageRoot: {
    padding: "18px 0",
    height: "400px",
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #DDDDDD",
  },
  image: {
    width: "80%",
    maxWidth: "435px",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "13px",
    backgroundColor: theme.palette.text.disabled,
  },
  commentsRoot: {
    flexGrow: "1",
    height: "0",
  },
  addCommentRoot: {
    padding: "9px 32px 15px",
    borderTop: "1px solid #DDDDDD",
  },
  addCommentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:not(:last-child)": {
      marginBottom: "7px",
    },
  },
  input: {
    width: "70%",
  },
  iconButton: {
    width: "40px",
    height: "40px",
    border: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
    display: "flex !important",
  },
  commentAvatar: {
    borderRadius: "50%",
    height: "36px",
    width: "36px",
    objectFit: "cover",
    marginRight: "5px",
  },
  commentName: {
    fontSize: "15px",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  commentImage: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    maxHeight: "400px",
    borderRadius: "13px",
  },
}));

const ActiveMarker = ({ setHeader }) => {
  const incidents = useSelector(state => state.mapReducer.orgIncidents);
  const announcements = useSelector(state => state.mapReducer.orgAnnouncements);
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const avatar = useSelector(state => state.userReducer.avatar);
  const name = useSelector(state => state.userReducer.name);
  const [comments, setComments] = useState([]);
  const [addedImage, setAddedImage] = useState(null);
  const [img, setImg] = useState(null);
  const [error, setError] = useState(false);
  const [nick, setNick] = useState("");
  const [comment, setComment] = useState("");
  const classes = useStyles({ color: activeMarker && colors[activeMarker.status] });
  const { type, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const errorImage = isLocalSpot ? noPhotoLs : noPhoto;
  const isIncident = type === "zgloszenie";
  const toDispatch = isIncident
    ? mapAction.searchSingleIncident
    : mapAction.searchSingleAnnouncement;

  useEffect(() => {
    if (setHeader) {
      if (activeMarker) {
        setHeader(renderHeader());
      } else {
        setHeader(null);
      }
    }
  }, [activeMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isIncident = type === "zgloszenie";
    const arr = isIncident ? incidents : announcements;
    const marker = arr.find(m => m.id === id);
    dispatch(mapAction.clearNewMarker());
    if (!marker) {
      dispatch(toDispatch({ id }));
    } else {
      dispatch(mapAction.setActiveMarkerId({ id, marker, type }));
    }
  }, [type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadComments = () => {
    if (type !== "zgloszenie") {
      return;
    }
    getIncidentComments(id)
      .then(response => {
        setComments(response.results.reverse());
      })
      .catch(() => {
        setComments([]);
      });
  };

  useEffect(() => {
    setComments([]);
    setComment("");
    loadComments();
  }, [type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const goBack = () => {
    dispatch(mapAction.clearActiveMarker());
    history.push("/mapa");
  };

  const handleNewImage = files => {
    const file = files[0];
    setImg(null);
    const reader = new FileReader();

    reader.onabort = () => setAddedImage(null);
    reader.onerror = () => setAddedImage(null);
    reader.onload = () => {
      const str = reader.result;
      setAddedImage(str);
      setImg(file);
    };
    reader.readAsDataURL(file);
  };

  const submitComment = () => {
    newComment(nick, comment, img, id).then(() => {
      setComment("");
      dispatch(toDispatch({ id }));
      loadComments();
    });
  };

  const addHeart = () => {
    const addFunc = isIncident ? createIncidentHeart : createAnnouncementHeart;
    addFunc(nick, id).then(() => {
      dispatch(toDispatch({ id }));
      loadComments();
    });
  };

  const deleteHeart = () => {
    const deleteFunc = isIncident ? removeIncidentHeart : removeAnnouncementHeart;
    deleteFunc(nick, id).then(() => {
      const toDispatch = isIncident
        ? mapAction.searchSingleIncident
        : mapAction.searchSingleAnnouncement;
      dispatch(toDispatch({ id }));
      loadComments();
    });
  };

  if (type !== "zgloszenie" && type !== "ogloszenie") {
    goBack();
  }

  if (!activeMarker) {
    return null;
  }

  const renderHeader = () => {
    if (!activeMarker) {
      return null;
    }

    return (
      <BackRow onGoBack={goBack}>
        <Box className={classes.titleAndIcon}>
          <Box className={classes.titleRoot}>
            <Typography className={classes.title}>
              {isIncident && `#${activeMarker.id} `}
              {activeMarker.categoryName}
            </Typography>
            {!activeMarker.address || activeMarker.address === "null" ? (
              <Box style={{ height: "24px" }} />
            ) : (
              <Typography className={classes.address}>
                {activeMarker.address.replace(", Polska", "")}
              </Typography>
            )}
          </Box>
          {isIncident && (
            <Box className={classes.circle}>
              <img
                src={markers[activeMarker.status]}
                alt={activeMarker.statusName}
                className={classes.circleIcon}
              />
            </Box>
          )}
        </Box>
      </BackRow>
    );
  };

  return (
    <Box className={classes.root}>
      {!setHeader && renderHeader()}
      <Box className={classes.contentRoot} data-body-scroll-lock-ignore>
        <Box className={classes.imageRoot}>
          <img
            onError={() => setError(true)}
            src={
              error
                ? errorImage
                : isIncident
                ? images.incident + `/${activeMarker.id}/${activeMarker.mediumImgId}.jpg`
                : images.announcement + `/${activeMarker.mediumImgId}.jpg`
            }
            alt=""
            className={classes.image}
          />
        </Box>
        <Box className={classes.commentsRoot}>
          <Comment
            comment={activeMarker}
            isTopComment
            isIncident={isIncident}
            addHeart={addHeart}
            deleteHeart={deleteHeart}
          />
          {comments.map(comment => (
            <Comment comment={comment} key={comment.createDate} isIncident />
          ))}
        </Box>
      </Box>
      {isIncident && (
        <Box className={classes.addCommentRoot} key={`${id}-${comments.length}`}>
          <Box className={classes.addCommentRow}>
            {isLoggedIn ? (
              <Box display="flex" alignItems="center">
                <img
                  src={images.avatar + `/${avatar}.jpg`}
                  alt={name}
                  className={classes.commentAvatar}
                />
                <Typography className={classes.commentName}>{name}</Typography>
              </Box>
            ) : (
              <TextField
                placeholder="Podpis"
                variant="filled"
                className={classes.input}
                value={nick}
                onChange={e => setNick(e.target.value)}
              />
            )}
            <Fab color="primary" size="small" onClick={() => submitComment()}>
              <AddIcon />
            </Fab>
          </Box>
          <Box className={classes.addCommentRow}>
            <TextField
              placeholder="Komentarz"
              variant="filled"
              multiline
              rowsMax={5}
              className={classes.input}
              onChange={e => setComment(e.target.value)}
            />
            <Dropzone maxFiles={1} accept="image/*" onDropAccepted={handleNewImage} noDrag>
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()}>
                  <input {...getInputProps()} />
                  <IconButton className={classes.iconButton}>
                    <CameraIcon />
                  </IconButton>
                </Box>
              )}
            </Dropzone>
          </Box>
          {addedImage && (
            <img
              src={addedImage}
              alt="Zdjęcie dodane przez użytkownika"
              className={classes.commentImage}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ActiveMarker;
