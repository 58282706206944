export const quotes = [
  {
    text: "Powiadomienia docierają do mieszkańców |natychmiast| i nie musimy za nie płacić jak za SMS-y.",
    author: "Tomasz Kałużny - Burmistrz Miasta i Gminy we Wrześni",
  },
  {
    text: "Według nas, wybraliśmy |najlepsze obecnie rozwiązanie| tego typu na rynku.",
    author: "Grzegorz Szuplewski - Burmistrz Miasta Piastowa",
  },
  {
    text: `Przypomnienia| o terminie |wywozu odpadów| to strzał w "10". Wiemy od mieszkańców że zawsze mieli z tym problem.`,
    author: "Maciej Mazur - Wójt gminy Nieporęt",
    startWithBold: true,
  },
  {
    text: "Jestem przekonany że ten system miał |duży wpływ| na moją wygraną w ostatnich wyborach.",
    author: "Włodarz z pewnego miasta",
  },
  {
    text: "Jesteśmy bardzo |zadowoloeni| Brakowało nam takiego |narzędzia| w dziale promocji.",
    author: "Dorota Brzozowska - Kierownik Działu Informacji Publicznej i Promocji Gminy Września",
  },
];
