import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Incident from "./Incident";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Autosizer from "react-virtualized-auto-sizer";
import { makeStyles } from "@material-ui/core/styles";

const rowsPerPage = 30;

const useStyles = makeStyles((theme) => ({
  listRoot: {
    flexGrow: "1",
    borderTop: "1px solid #DDDDDD",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 170px)",
    }
  },
}));

const List = ({ markers, isIncident }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loadedIncidents, setLoadedIncidents] = useState([]);
  const [loadedIndex, setLoadedIndex] = useState(0);
  const hasMounted = useRef(false);
  const listRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isItemLoaded = index => {
    return loadedIncidents.length - index > 1;
  };

  const loadNextPage = (start) => {
    let tmp = start === 0 ? [] : [...loadedIncidents];
    const startIndex = start === 0 ? start : loadedIndex;
    const slice = markers.slice(startIndex, startIndex + rowsPerPage);
    tmp = tmp.concat(slice);
    setLoadedIndex(startIndex + slice.length);
    setLoadedIncidents(tmp);
  };

  useEffect(() => {
    loadNextPage(0);
    if (hasMounted.current && listRef.current) {
      listRef.current.resetloadMoreItemsCache();
    }
    hasMounted.current = true;
  }, [markers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Box className={classes.listRoot} data-body-scroll-lock-ignore>
        <Autosizer>
          {({ height, width }) => (
            <InfiniteLoader
              loadMoreItems={loadNextPage}
              itemCount={loadedIncidents.length}
              isItemLoaded={isItemLoaded}
              ref={listRef}
              threshold={2}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemSize={isMobile ? 141 : 150}
                  itemCount={loadedIncidents.length}
                  overscanCount={isMobile ? 3 : 10}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {({ index, style }) => (
                    <Incident
                      incident={loadedIncidents[index]}
                      style={style}
                      key={loadedIncidents[index].id}
                      isIncident={isIncident}
                    />
                  )}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </Autosizer>
      </Box>
    </React.Fragment>
  );
};

export default List;
