import axios from "axios";

const getAddress = ({ lat, lng }) =>
  axios
    .get("https://maps.googleapis.com/maps/api/geocode/json", {
      params: {
        key: "AIzaSyAfTq4c-WQL1RWc6SgmqzyGEdI3uBCufKE",
        language: "pl",
        region: "pl",
        latlng: `${lat},${lng}`,
      },
    })
    .then(response => response.data.results[0]);

export default getAddress;
