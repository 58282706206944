import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../static/km-logo-no-text.png";
import kmText from "../../../static/km-logo-text.png";
import lsText from "../../../static/ls-logo-text.png";

const links = [
  {
    name: "Dla urzędów",
    link: "/dla-urzedow",
  },
  {
    name: "Regulamin",
    link: "/regulamin",
  },
  {
    name: "Załóż konto",
    link: "/rejestracja",
  },
  {
    name: "Zaloguj",
    link: "/login",
  },
  {
    name: "Dodaj zgłoszenie",
    link: "/mapa",
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "90px",
    padding: "15px 440px 10px 180px",
    borderTop: "1px solid #DDDDDD",
    [theme.breakpoints.down(1600)]: {
      paddingLeft: "min(30px, 3%)",
      paddingRight: "183px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
      height: "unset",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logoRoot: {
    height: "46px",
    display: "flex",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  pin: {
    height: "100%",
    marginRight: "15px",
  },
  nameRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    height: "27px",
  },
  xentivo: {
    fontSize: "10px",
    color: theme.palette.text.primary,
    letterSpacing: "1px",
  },
  allLinks: {
    marginLeft: "30px",
    flexGrow: "1",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: "13px",
    textTransform: "uppercase",
    letterSpacing: "1.3px",
    lineHeight: "19px",
    marginRight: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      "&:last-of-type": {
        marginRight: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
      "&:not(:last-of-type)": {
        marginBottom: "30px",
      },
    },
  },
  contactRoot: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  contactHeader: {
    textAlign: "unset",
    marginRight: "0px",
  },
  smallContact: {
    display: "block",
    marginTop: "5px",
    whiteSpace: "break-spaces",
    color: theme.palette.primary.main,
    fontSize: "13px",
    letterSpacing: "1.3px",
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <Box className={classes.root}>
      <Link to="/" className={classes.logoRoot}>
        <img
          src={logo}
          alt={isLocalSpot ? "logo LocalSpot" : "logo Komunikatora Miejskiego"}
          className={classes.pin}
        />
        <Box>
          <img alt="" src={isLocalSpot ? lsText : kmText} className={classes.name} />
          <Typography className={classes.xentivo}>® Xentivo 2021</Typography>
        </Box>
      </Link>
      <Box className={classes.allLinks}>
        {links.map(link => (
          <Link className={classes.link} to={link.link} key={link.link}>
            {link.name}
          </Link>
        ))}
      </Box>
      <Box className={classes.contactRoot}>
        <Typography className={`${classes.link} ${classes.contactHeader}`}>Kontakt</Typography>
        <MuiLink
          href={
            isLocalSpot ? "mailto:kontakt@localspot.pl" : "mailto:kontakt@komunikatormiejski.pl"
          }
          className={classes.smallContact}
        >
          {isLocalSpot ? "kontakt@localspot.pl" : "kontakt@komunikatormiejski.pl"}
        </MuiLink>
        <MuiLink href="tel:222904434" className={classes.smallContact}>
          (48) 22 290 44 34
        </MuiLink>
      </Box>
    </Box>
  );
};

export default Footer;
