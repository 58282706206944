import React, { useState, useEffect, useRef } from "react";
import { MapContainer, FeatureGroup, GeoJSON, useMap } from "react-leaflet";
import { govMarkers } from "../../static/map/govMarkers";
import MapMarker from "./components/MapMarker";
import StyledLayer from "./components/StyledLayer";

const BoundsController = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds);
    }
  }, [bounds]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const TownHallMap = ({ className, announcements, geoJSON }) => {
  const layer = useRef(null);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (layer.current) {
      setBounds(layer.current.getBounds());
    }
  }, [announcements]);

  return (
    <MapContainer center={[52.015, 18.123]} zoom={7} maxZoom={18} className={className}>
      <StyledLayer />
      <BoundsController bounds={bounds} />
      <FeatureGroup>
        {announcements
          .filter(a => a.latitude && a.longitude)
          .map(marker => (
            <MapMarker
              key={marker.id}
              marker={marker}
              pos={[marker.latitude, marker.longitude]}
              icon={govMarkers[marker.category]}
              type="announcement"
              small
              disableClick
            />
          ))}
      </FeatureGroup>
      <GeoJSON ref={layer} data={geoJSON} pathOptions={{ fillOpacity: "0.1" }} />
    </MapContainer>
  );
};

export default TownHallMap;
