import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SearchField from "./SearchField";
import HeartIcon from "../../../../../../static/map/sidebar/HeartIcon";
import BoxIcon from "../../../../../../static/map/sidebar/BoxIcon";
import { outline } from "../../../../../../static/map/sidebar/search/buttons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../../../../../redux/modules/mapReducer";
import { Tooltip } from "@material-ui/core";
import markerTypes from "../../../../../../util/markerTypes";

const useStyles = makeStyles(theme => ({
  upperRoot: {
    display: "flex",
    alignItems: "center",
    padding: "0 26px",
    marginBottom: "26px",
  },
  leftButtonRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: "1",
    maxWidth: "100%",
  },
  iconButton: {
    color: "#707070",
    width: "40px",
    height: "40px",
  },
  iconButtonPrimary: {
    color: theme.palette.primary.main,
    width: "40px",
    height: "40px",
  },
  divider: {
    width: "1px",
    height: "50px",
    backgroundColor: "#DBDBDB",
  },
  markersButtons: {
    display: "flex",
    justifyContent: "space-around",
    flexGrow: "1",
  },
  image: {
    width: "49px",
    height: "53px",
    objectFit: "contain",
  },
  button: {
    width: "73px",
    height: "73px",
  },
  input: {
    width: "100%",
  },
}));

const FilterBar = ({ active, types, activeIcons, searchAction, changeActive, placeholder }) => {
  const classes = useStyles();
  const showFavourite = useSelector(state => state.mapReducer.showFavourite);
  const showArchive = useSelector(state => state.mapReducer.showArchive);
  const dispatch = useDispatch();
  const timeout = useRef();

  const handleClick = toggle => {
    dispatch(changeActive({ toggle }));
  };

  const handleSearch = search => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => dispatch(searchAction({ search })), 350);
  };

  const toggleFavourite = () => {
    dispatch(mapAction.toggleFavourite());
  };

  const toggleArchive = () => {
    dispatch(mapAction.toggleArchive());
  };

  return (
    <React.Fragment>
      <Box className={classes.upperRoot}>
        <Box className={classes.leftButtonRoot}>
          <Tooltip title="Polubione">
            <IconButton
              className={showFavourite ? classes.iconButtonPrimary : classes.iconButton}
              onClick={() => toggleFavourite()}
            >
              <HeartIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Archiwum">
          <IconButton
            className={showArchive ? classes.iconButtonPrimary : classes.iconButton}
            onClick={() => toggleArchive()}
          >
            <BoxIcon />
          </IconButton>
          </Tooltip>
        </Box>
        <Box className={classes.divider} />
        <Box className={classes.markersButtons}>
          {types.map(type => (
            <Tooltip title={markerTypes(type)}>
              <IconButton key={type} onClick={() => handleClick(type)} className={classes.button}>
                <img
                  src={active.includes(type) ? activeIcons[type] : outline[type]}
                  alt=""
                  className={classes.image}
                />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box className={classes.upperRoot}>
        <SearchField
          placeholder={placeholder}
          variant="filled"
          className={classes.input}
          onChange={e => handleSearch(e.target.value)}
        />
      </Box>
    </React.Fragment>
  );
};

export default FilterBar;
