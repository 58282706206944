import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import CameraIcon from "@material-ui/icons/CameraAlt";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: props => (props.img ? "center" : "space-between"),
    alignItems: "center",
    width: "100%",
    height: props => (props.img ? "165px" : "48px"),
    padding: props => (props.img ? "0" : "0 21px"),
    marginBottom: "3px",
    backgroundColor: "#F7F7F7",
    borderRadius: "20px",
    border: "1px solid #DDDDDD",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
  },
  placeholderText: {
    fontSize: "15px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  image: {
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    zIndex: "1",
  },
  backgroudImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: "0.2",
    objectFit: "cover",
  },
  bottomRoot: {
    display: "flex",
    alignItems: "center",
  },
  helperText: {
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "1.2px",
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    lineHeight: "17px",
    width: "0",
    flexGrow: "1",
  },
  button: {
    height: "27px",
    borderRadius: "13.5px",
    width: "130px",
    fontSize: "15px",
  },
}));

const PhotoPicker = ({ handleChange }) => {
  const [img, setImg] = useState(null);
  const classes = useStyles({ img });

  const handleRead = files => {
    const file = files[0];
    const reader = new FileReader();

    reader.onabort = clearImage;
    reader.onerror = clearImage;
    reader.onload = () => {
      const binaryStr = reader.result;
      setImg(binaryStr);
      handleChange("image", file);
    };
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setImg(null);
    handleChange("image", null);
  };

  return (
    <Box>
      <Dropzone accept="image/*" maxFiles={1} onDropAccepted={handleRead}>
        {({ getRootProps, getInputProps }) => (
          <Box className={classes.root} {...getRootProps()}>
            <input {...getInputProps()} />
            {img ? (
              <React.Fragment>
                <img alt="" src={img} className={classes.backgroudImage} />
                <img alt="Zdjęcie dodane przez użytkownika" src={img} className={classes.image} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography className={classes.placeholderText}>
                  Kliknij lub upuść zdjęcie
                </Typography>
                <CameraIcon className={classes.icon} />
              </React.Fragment>
            )}
          </Box>
        )}
      </Dropzone>
      {img && (
        <Box className={classes.bottomRoot}>
          <Typography className={classes.helperText}>
            Jeśli chcesz załączyć więcej zdjęć zrób to dodając komentarz ze zdjęciem pod
            zgłoszeniem.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => clearImage()}
          >
            Usuń zdjęcie
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PhotoPicker;
