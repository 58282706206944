import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: "1",
    maxWidth: "70px",
    margin: "0 5px 5px",
  },
  image: {
    width: "50px",
    height: "53px",
    objectFit: "contain",
    objectPosition: "top",
    marginBottom: "5px",
  },
  name: {
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "1.3px",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "11px",
      letterSpacing: "1.1px",
      lineHeight: "17px",
    },
  },
}));

const County = ({ src, name }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img src={src} className={classes.image} alt="" />
      <Typography className={classes.name}>{name}</Typography>
    </Box>
  );
};

export default County;
