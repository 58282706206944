import React from "react";
import FilledInput from "../../../../../../components/FilledInput/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const SearchField = props => (
  <FilledInput
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export default SearchField;
