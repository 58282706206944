import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FilterBar from "./components/FilterBar";
import List from "./components/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { govMarkers } from "../../../../../static/map/govMarkers";
import { mapAction } from "../../../../../redux/modules/mapReducer";

const AnnouncementsList = ({ showCollapse }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const activeTypes = useSelector(state => state.mapReducer.activeTypes);
  const announcements = useSelector(state => state.mapReducer.announcements);

  const Wrapper = isMobile ? Collapse : React.Fragment;

  return (
    <React.Fragment>
      <Wrapper key={isMobile ? showCollapse : undefined}>
        <FilterBar
          types={["GOV", "BUS", "OSI", "ATR"]}
          activeIcons={govMarkers}
          active={activeTypes}
          changeActive={mapAction.changeActiveTypes}
          searchAction={mapAction.searchAnnouncements}
          placeholder="SZUKAJ OGŁOSZEŃ"
        />
      </Wrapper>
      <List markers={announcements} />
    </React.Fragment>
  );
};

export default AnnouncementsList;
