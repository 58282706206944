import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AirStation from "./AirStation";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "25px 15px 25px 9%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "15px 35px",
      borderBottom: "1px solid #DDDDDD",
    },
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  loaderRoot: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    marginRight: "15px",
  },
  header: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
      lineHeight: "20px",
    },
  },
  suggestions: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    lineHeight: "18px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
      lineHeight: "20px",
    },
  },
  error: {
    letterSpacing: "0px",
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
  },
  dataSource: {
    fontSize: "12px",
    color: theme.palette.primary.main,
  },
}));

const AirQuality = ({ airStation, airStationAddress }) => {
  const classes = useStyles();
  const [station, setStation] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.GIOS_API_URL+"/pjp-api/rest/aqindex/getIndex/" + airStation)
      .then(response => {
        setStation(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setStation({});
        setIsLoading(false);
      });
  }, [airStation]);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <CloudOutlinedIcon color="primary" className={classes.icon} />
        <Typography className={classes.header}>Indeks jakości powietrza</Typography>
      </Box>
      {isLoading && (
        <Box className={classes.loaderRoot}>
          <CircularProgress size={40} />
        </Box>
      )}
      {!isLoading && Object.keys(station).length > 0 && (
        <AirStation station={station} address={airStationAddress} />
      )}
      {!isLoading && Object.keys(station).length === 0 && (
        <Typography className={clsx(classes.suggestions, classes.error)}>Wystąpił błąd</Typography>
      )}
      <Typography className={classes.dataSource}>
        Źródło danych: Główny Inspektorat Ochrony Środowiska
      </Typography>
    </Box>
  );
};

export default AirQuality;
