import React, { useState, useMemo, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Redirect, useParams } from "react-router-dom";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AirQuality from "./components/AirQuality/AirQuality";
import TownHallMap from "../../components/Map/TownHallMap";
import Announcements from "./components/Announcements/Announcements";
import getAnnoucementsByCity from "../../services/townHall/getAnnoucementsByCity";
import clsx from "clsx";
import { townData } from "./cities/townData";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    alignItems: "stretch",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sidebar: {
    width: "30%",
    borderRight: "1px solid #DDDDDD",
    [theme.breakpoints.down(1280)]: {
      width: "40%",
    },
  },
  rightSide: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "60%",
    },
  },
  mapContainer: {
    width: "100%",
    height: "50%",
    minHeight: "400px",
  },
  announcements: {
    flexGrow: "1",
    width: "100%",
    borderTop: "1px solid #DDDDDD",
  },
  detailBox: {
    padding: "25px 15px 25px 9%",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "15px 35px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  coatOfArms: {
    height: "128px",
    width: "128px",
    marginRight: "40px",
    objectFit: "contain",
    [theme.breakpoints.down(1280)]: {
      height: "64px",
      width: "64px",
      minWidth: "64px",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "96px",
      width: "96px",
      minWidth: "96px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "64px",
      width: "64px",
      minWidth: "64px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "bolder",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
    },
  },
  cityName: {
    fontWeight: "bold",
    fontSize: "30px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },
  address: {
    fontSize: "20px",
    letterSpacing: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
  },
  detailText: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  collapseRoot: {
    position: "relative",
  },
  collapseButton: {
    position: "absolute",
    width: "200px",
    top: "10px",
    right: "10px",
    zIndex: "1001",
  },
  icon: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
  openIcon: {
    transform: "rotate(180deg)",
  },
  afterMapContainer: {
    zIndex: "1000",
    paddingTop: "58px",
    transition: theme.transitions.create(["box-shadow", "padding-top"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  afterMapContainerOpen: {
    paddingTop: "0px",
    boxShadow: "0px -6px 6px #0000004D",
  },
}));

const TownPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [announcements, setAnnoucements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const { city: cityName } = useParams();
  const city = townData[cityName];

  useEffect(() => {
    if (!city) {
      return;
    }

    getAnnoucementsByCity(city.id)
      .then(data => {
        setAnnoucements(data.results);
      })
      .catch(() => {
        setAnnoucements([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [city]);

  useEffect(() => {
    if (!isMobile) {
      setCollapseOpen(false);
    }
  }, [isMobile]);

  const renderCityDetails = useMemo(() => {
    if (!city) {
      return null;
    }

    return (
      <Box className={classes.detailBox}>
        <Box display="flex" alignItems="center" alignSelf="center">
          <img className={classes.coatOfArms} src={city.logo} alt="" />
          <Box className={classes.addressContainer}>
            <Typography className={classes.header}>{city.gmina ? "Gmina" : "Miasto"}</Typography>
            <Typography className={classes.cityName}>{city.name}</Typography>
            <Typography className={classes.address}>{city.address}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderOpeningHours = useMemo(() => {
    if (!city) {
      return null;
    }

    let day = new Date().getDay();
    const nextDay = day;
    day = day > 0 ? day - 1 : 6;
    const openingHours = city.openingHours[day];
    const now = new Date().getHours() * 60 + new Date().getMinutes();
    // Empty object - city office is closed
    const from = openingHours?.from ? openingHours.from.split(":") : -1;
    const to = openingHours?.to ? openingHours.to.split(":") : -1;
    const fromTime = from !== -1 ? Number.parseInt(from[0]) * 60 + Number.parseInt(from[1]) : -1;
    const toTime = to !== -1 ? Number.parseInt(to[0]) * 60 + Number.parseInt(to[1]) : -1;

    let text;

    if (!openingHours || !openingHours.from || now > toTime) {
      text = "jutro ";
      const nextOpeningHours = city.openingHours[nextDay];
      if (!nextOpeningHours) {
        text += "nieczynne";
      } else {
        text += `czynne od ${nextOpeningHours.from} do ${nextOpeningHours.to}`;
      }
    } else if (now < fromTime) {
      text = `dziś czynne od ${openingHours.from} do ${openingHours.to}`;
    } else {
      text = `dziś czynne do ${openingHours.to}`;
    }

    return (
      <Box className={classes.detailBox}>
        <Typography className={classes.header}>Godziny otwarcia</Typography>
        <Typography className={classes.detailText}>
          <span className={classes.highlight}>
            {fromTime <= now && now <= toTime ? "Czynne" : "Nieczynne"}
          </span>
          , {text}
        </Typography>
      </Box>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderContact = useMemo(() => {
    if (!city) {
      return null;
    }

    return (
      <Box className={classes.detailBox}>
        <Typography className={classes.header}>Kontakt</Typography>
        <Link underline="hover" href={`mailto:${city.email}`} className={classes.detailText}>
          {city.email}
        </Link>
        <Link
          underline="hover"
          href={city.www.replace("www.", "https://")}
          className={classes.detailText}
        >
          {city.www}
        </Link>
        <Link underline="hover" href={``} className={classes.detailText}>
          +48 22 884 90 91
        </Link>
      </Box>
    );
  }, [city]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!city) {
    return <Redirect to="/" />;
  }

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Box className={classes.sidebar}>
          {renderCityDetails}
          {renderOpeningHours}
          {renderContact}
          <AirQuality airStation={city.airStation} airStationAddress={city.airStationAddress} />
        </Box>
        <Box className={classes.rightSide}>
          <TownHallMap
            className={classes.mapContainer}
            geoJSON={city.geoJSON}
            announcements={announcements}
          />
          <Box className={classes.announcements}>
            <Announcements announcements={announcements} isLoading={isLoading} />
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        {renderCityDetails}
        <Box className={classes.collapseRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.collapseButton}
            endIcon={
              <ExpandMore className={clsx(classes.icon, { [classes.openIcon]: collapseOpen })} />
            }
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            {collapseOpen ? "Ukryj" : "Pokaż"} mapę
          </Button>
          <Collapse in={collapseOpen}>
            <TownHallMap
              className={classes.mapContainer}
              geoJSON={city.geoJSON}
              announcements={announcements}
            />
          </Collapse>
        </Box>
        <Box
          className={clsx(classes.afterMapContainer, {
            [classes.afterMapContainerOpen]: collapseOpen,
          })}
        >
          {renderOpeningHours}
          {renderContact}
          <AirQuality airStation={city.airStation} airStationAddress={city.airStationAddress} />
          <Announcements announcements={announcements} />
        </Box>
      </Hidden>
    </Box>
  );
};

export default TownPage;
