import { createSagaAction } from "../../util/createSagaAction";

export const userConstants = {
  LOGIN: createSagaAction("LOGIN"),
  LOGOUT: "LOGOUT",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
};

export const userAction = {
  login: ({ email, password }) => ({
    type: userConstants.LOGIN.ACTION,
    email,
    password,
  }),
  logout: () => ({
    type: userConstants.LOGOUT,
  }),
  getUserDetails: () => ({
    type: userConstants.GET_USER_DETAILS,
  }),
  clearMessages: () => ({
    type: userConstants.CLEAR_MESSAGES,
  }),
};

const initialState = {
  isLoggedIn: false,
  isLoadingDetails: false,
  isLoggingIn: false,
  name: "",
  avatar: null,
  apiKey: "",

  loginErrorMessage: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN.ACTION: {
      return {
        ...state,
        isLoggingIn: true,
      };
    }
    case userConstants.LOGIN.SUCCESS: {
      const user = action.results && action.results[0] ? action.results[0] : null;
      window.localStorage.setItem("apiKey", action.token);
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        isLoadingDetails: false,
        name: user ? user.nick : "Zalogowano",
        avatar: user ? user.avatar : null,
        apiKey: action.token,
        loginErrorMessage: null,
      };
    }
    case userConstants.LOGIN.FAILED: {
      window.localStorage.removeItem("apiKey");
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        isLoadingDetails: false,
        name: "",
        avatar: null,
        apiKey: "",
        loginErrorMessage: action.message ? action.message : "Wystąpił błąd",
      };
    }

    case userConstants.LOGOUT: {
      window.localStorage.removeItem("apiKey");
      return {
        ...initialState,
      };
    }

    case userConstants.GET_USER_DETAILS: {
      return {
        ...state,
        isLoadingDetails: true,
      };
    }

    case userConstants.CLEAR_MESSAGES: {
      return {
        ...state,
        loginErrorMessage: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
