import React from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import avatarPlaceholder from "../../../static/navbar/avatar.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userAction } from "../../../redux/modules/userReducer";
import { images } from "../../../services/urls";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: "40px",
    width: "40px",
    padding: "3px",
    boxSizing: "border-box",
    marginRight: "15px",
    boxShadow: "0px 3px 6px #0000004D",
    backgroundColor: "#E9ECF0",
  },
  avatarImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noBorder: {
    border: "unset",
  },
  smallAvatar: {
    width: "20px",
    height: "20px",
  },
  avatarIcon: {
    color: theme.palette.secondary.main,
  },
  name: {
    textTransform: "uppercase",
    marginRight: "40px",
    minWidth: "55px",
    [theme.breakpoints.down("xs")]: {
      flexGrow: "1",
    }
  },
}));

const LoginBox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const isLoadingDetails = useSelector(state => state.userReducer.isLoadingDetails);
  const name = useSelector(state => state.userReducer.name);
  const avatar = useSelector(state => state.userReducer.avatar);

  const handleLogout = () => {
    dispatch(userAction.logout());
  };

  return (
    <Box className={classes.root}>
      <Avatar className={classes.avatar}>
        {isLoggedIn && avatar ? (
          <img className={`${classes.avatarImage} ${classes.noBorder}`} src={`${images.avatar}/${avatar}.jpg`} alt="" />
        ) : (
          <Box className={classes.avatarImage}>
            <img className={classes.smallAvatar} src={avatarPlaceholder} alt="" />
          </Box>
        )}
      </Avatar>
      <Typography className={classes.name}>
        {isLoadingDetails ? "" : isLoggedIn ? name : "Gość"}
      </Typography>
      {isLoggedIn ? (
        <Button variant="contained" color="primary" onClick={() => handleLogout()}>
          Wyloguj
        </Button>
      ) : (
        <Button variant="contained" color="primary" component={Link} to="/login">
          Zaloguj
        </Button>
      )}
    </Box>
  );
};

export default LoginBox;
