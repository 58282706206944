import React from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Sidebar from "./components/Sidebar/Sidebar";
import MobileSidebar from "./components/Sidebar/MobileSidebar";
import Map from "../../components/Map/Map";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 188px)",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "calc(100vh - 170px)",
    },
  },
  mapRoot: {
    flexGrow: "1",
  },
}));

const MapPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Box width="30%" minWidth="380px">
          <Sidebar />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <MobileSidebar />
      </Hidden>
      <Map className={classes.mapRoot} />
    </Box>
  );
};

export default MapPage;
