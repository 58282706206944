import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { throttle } from "../util/throttle";
import rootSaga from "./sagas/sagas";
import * as reducers from "./modules/index";

const reducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  return reducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    return;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export default function configureStore() {
  const middlewareEnhancer = applyMiddleware(sagaMiddleware);

  const enhancers = [middlewareEnhancer];
  const composedEnhancer = composeWithDevTools(...enhancers);

  const persistedState = loadState();

  const store = createStore(rootReducer, persistedState, composedEnhancer);

  sagaMiddleware.run(rootSaga);

  store.subscribe(
    throttle(() => {
      saveState({});
    }, 1000)
  );

  return store;
}

export const store = configureStore();
