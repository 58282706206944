import piastow from "../../../../static/topBanner/piastow.png";
import wisniew from "../../../../static/topBanner/wisniew.png";
import plock from "../../../../static/topBanner/plock.png";
import zgierz from "../../../../static/topBanner/zgierz.png";
import wrzesnia from "../../../../static/topBanner/wrzesnia.png";
import nieporet from "../../../../static/topBanner/nieporet.png";
import srem from "../../../../static/topBanner/srem.png";

export const counties = [
  { name: "Piastów", src: piastow },
  { name: "Wiśniew", src: wisniew },
  { name: "Płock", src: plock },
  { name: "Zgierz", src: zgierz },
  { name: "Września", src: wrzesnia },
  { name: "Nieporęt", src: nieporet },
  { name: "", src: srem },
];
