import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { markers } from "../../../../../../static/map/sidebar/markers";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../../../services/urls";
import noPhoto from "../../../../../../static/map/sidebar/no-photo.png";
import noPhotoLs from "../../../../../../static/map/sidebar/no-photo-ls.png";
import { useHistory } from "react-router";
import moment from "moment";
import "moment/locale/pl";
moment.locale("pl");

const colors = {
  1: "#D72D21",
  2: "#EFA852",
  3: "#418B5C",
  4: "#BABABA",
};

const useStyles = makeStyles(theme => ({
  root: {
    height: "150px",
    padding: "13px 18px 9px 20px",
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #DDDDDD",
    boxSizing: "border-box",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "141px",
      padding: "13px 15px",
    },
  },
  imageRoot: {
    width: "100px",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "79px",
    },
  },
  image: {
    width: "100px",
    height: "108px",
    minHeight: "108px",
    borderRadius: "15px",
    backgroundColor: theme.palette.text.disabled,
    objectFit: "cover",
    overflow: "hidden",
    marginBottom: "2px",
    [theme.breakpoints.down("xs")]: {
      width: "79px",
      height: "93px",
      minHeight: "93px",
    },
  },
  date: {
    fontSize: "13px",
    letterSpacing: "1.3px",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      letterSpacing: "1px",
    },
  },
  middleRoot: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 110px)",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 89px)",
    },
  },
  topRoot: {
    width: "100%",
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-between",
  },
  textRoot: {
    maxWidth: props => (props.isIncident ? "calc(100% - 55px)" : "100%"),
  },
  header: {
    maxWidth: "100%",
    fontSize: "18px",
    fontWeight: "500",
    letterSpacing: "0.9px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      letterSpacing: "1.5px",
      whiteSpace: "wrap",
    },
  },
  address: {
    maxWidth: "100%",
    fontSize: "15px",
    letterSpacing: "0.75px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
      marginBottom: "5px",
    },
  },
  description: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    color: theme.palette.text.secondary,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      letterSpacing: "1.5px",
      lineHeight: "17px",
    },
  },
  iconsRoot: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  icon: {
    width: "16px",
    height: "13px",
    color: theme.palette.text.secondary,
    marginRight: "5px",
    objectFit: "contain",
  },
  iconText: {
    fontSize: "15px",
    color: theme.palette.text.secondary,
    "&:not(:last-child)": {
      marginRight: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
    },
  },
  circle: {
    minWidth: "34px",
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    backgroundColor: props => props.color,
    boxShadow: "0px 3px 6px #00000040",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  circleIcon: {
    width: "22px",
    height: "22px",
    filter: "drop-shadow(0 3px 3px #00000040)",
  },
  avatarRoot: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    overflow: "hidden",
    objectFit: "cover",
    marginRight: "5px",
  },
  name: {
    color: theme.palette.text.primary,
    margin: "unset",
    maxWidth: "calc(100% - 29px)",
  },
}));

const Incident = ({ incident, style, isIncident }) => {
  const classes = useStyles({ color: colors[incident.status], isIncident });
  const [error, setError] = useState(false);
  const createDate = isIncident ? moment(incident.createDate) : moment(incident.startDate);
  const history = useHistory();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const errorImage = isLocalSpot ? noPhotoLs : noPhoto;

  return (
    <Box
      className={classes.root}
      style={style}
      onClick={() =>
        history.push(`/mapa/${isIncident ? "zgloszenie" : "ogloszenie"}/${incident.id}`)
      }
    >
      <Box className={classes.imageRoot}>
        <img
          alt="Zdjęcie zgłoszenia"
          onError={() => setError(true)}
          src={
            error
              ? errorImage
              : incident.smallImgId
              ? `${images.incident}/${incident.id}/${incident.smallImgId}.jpg`
              : incident.mediumImgId
              ? `${images.announcement}/${incident.mediumImgId}.jpg`
              : errorImage
          }
          className={classes.image}
        />
        <Typography className={classes.date}>
          {createDate.isAfter(moment().subtract(2, "days"))
            ? createDate.fromNow()
            : createDate.format("DD.MM.YYYY")}
        </Typography>
      </Box>
      <Box className={classes.middleRoot}>
        <Box className={classes.topRoot}>
          <Box className={classes.textRoot}>
            <Typography className={classes.header}>
              {isIncident ? `#${incident.id} ${incident.categoryName}` : incident.title}
            </Typography>
            <Typography className={isIncident ? classes.address : classes.description}>
              {isIncident
                ? incident.address.replace(", Polska", "")
                : incident.description.length > 68
                ? incident.description.substring(0, 65).trim() + "..."
                : incident.description}
            </Typography>
            {isIncident && (
              <Box className={classes.avatarRoot}>
                <img
                  src={incident.avatar ? `${images.avatar}/${incident.avatar}.jpg` : markers.avatar}
                  alt=""
                  className={classes.avatar}
                />
                <Typography className={`${classes.address} ${classes.name}`}>
                  {incident.nick}
                </Typography>
              </Box>
            )}
          </Box>
          {isIncident && (
            <Box className={classes.circle}>
              <img
                src={markers[incident.status]}
                alt={incident.statusName}
                className={classes.circleIcon}
              />
            </Box>
          )}
        </Box>
        <Box className={classes.iconsRoot}>
          <img src={markers.likes} className={classes.icon} alt="" />
          <Typography className={classes.iconText}>
            {incident.heartsCount ? incident.heartsCount : "0"}
          </Typography>
          {isIncident && <img src={markers.comments} className={classes.icon} alt="" />}
          {isIncident && (
            <Typography className={classes.iconText}>{incident.commentsCount}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Incident;
