import React, { useRef, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import BluePinIcon from "../../../../static/BluePinIcon";
import Circle from "@material-ui/icons/RadioButtonUnchecked";
import CircleActive from "@material-ui/icons/RadioButtonChecked";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import mobileBg from "../../../../static/mobile-bg.png";
import hand from "../../../../static/hand.png";
import "react-swipeable-views/dist/legacy-browser-support.css"; //legacy browser support for SwipeableViews

const VirtualizedSwipeableViews = virtualize(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 78px)",
    backgroundImage: `url(${mobileBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#14387B",
    padding: "50px 100px",
    position: "relative",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "50px 100px 0px",
      backgroundRepeat: "repeat",
      backgroundSize: "contain",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "37px 37px 0px",
      height: "calc(100vh - 60px)",
    },
  },
  icon: {
    height: "45px",
    width: "33px",
    color: "#FFFFFF",
    marginRight: "13px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "20px",
    },
  },
  header: {
    fontSize: "35px",
    letterSpacing: "3.5px",
    fontWeight: "bold",
    lineHeight: "53px",
    textTransform: "uppercase",
    marginBottom: "23px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "30px",
      whiteSpace: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
    },
  },
  subtext: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    lineHeight: "38px",
    maxWidth: "620px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
      lineHeight: "20px",
    },
  },
  bottomRoot: {
    width: "calc(100% - 100px)",
    height: "0",
    flexGrow: "1",
    marginLeft: "100px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      width: "100%",
    },
  },
  detailText: {
    fontSize: "25px",
    fontWeight: "600",
    letterSpacing: "2.5px",
    lineHeight: "38px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
      lineHeight: "20px",
      maxWidth: "250px",
      alignSelf: "flex-start",
    },
  },
  alignCenter: {
    marginTop: "180px",
    flexGrow: "1",
  },
  alignEnd: {
    alignSelf: "flex-end",
    marginBottom: "150px",
  },
  imageRoot: {
    width: "340px",
    height: "calc(100% - 50px)",
    minHeight: "700px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "50px", //compensate for button height
  },
  image: {
    height: "100%",
    objectFit: "contain",
  },
  button: {
    width: "240px",
    fontSize: "22px",
    letterSpacing: "2.2px",
    height: "50px",
    borderRadius: "25px",
    position: "absolute",
    bottom: "25px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  buttonIcon: {
    fontSize: "36px !important",
  },
  listRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    marginBottom: "20px",
    width: "100%",
  },
  buttonsRoot: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    color: "#FFFFFF",
    "&:not(:last-of-type)": {
      marginRight: "10px",
    },
  },
  mobileImageRoot: {
    height: "0px",
    flexGrow: "2",
    display: "flex",
    alignItems: "flex-end",
    flexBasis: "50%",
  },
}));

const MobileApp = () => {
  const classes = useStyles();
  const scrollRef = useRef();
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  useEffect(() => {
    if (!isMobile) {
      setIndex(0);
    }
  }, [isMobile]);

  const handleScroll = () => {
    if (!scrollRef.current) {
      return;
    }

    const offset = scrollRef.current.getBoundingClientRect().top;
    window.scrollBy({ top: offset - 78, behavior: "smooth" });
  };

  const texts = [
    `${
      isLocalSpot ? "LocalSpot" : "Komunikator Miejski"
    } łączy mieszkańca ze swoim urzędem poprzez aplikację mobilną oraz stronę internetową.`,
    "Urząd zamieszcza w systemie informacje istotne dla mieszkańca…",
    "Mieszkaniec otrzymuje te informacje i zgłasza problemy zauważone w swojej okolicy.",
  ];

  const renderHeader = () => (
    <Box display="flex">
      <BluePinIcon fill="#14387B" className={classes.icon} />
      <Box>
        <Typography component="h2" className={classes.header}>
          Twoje miasto w zasięgu ręki
        </Typography>
        <Hidden smDown>
          <Typography className={classes.subtext}>{texts[0]}</Typography>
        </Hidden>
      </Box>
    </Box>
  );

  const renderSlide = ({ key, index }) => {
    return (
      <Typography key={key} className={classes.detailText}>
        {texts[mod(index, 3)]}
      </Typography>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {renderHeader()}
        <Hidden smDown>
          <Box className={classes.bottomRoot}>
            <Typography className={`${classes.detailText} ${classes.alignCenter}`}>
              {texts[1]}
            </Typography>
            <Box className={classes.imageRoot}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ExpandMoreIcon className={classes.buttonIcon} />}
                onClick={() => handleScroll()}
                className={classes.button}
              >
                Dalej
              </Button>
              <img alt="" src={hand} className={classes.image} />
            </Box>
            <Typography className={`${classes.detailText} ${classes.alignEnd}`}>
              {texts[2]}
            </Typography>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.listRoot}>
            <VirtualizedSwipeableViews
              slideRenderer={renderSlide}
              slideCount={3}
              onChangeIndex={index => setIndex(index)}
              index={index}
              style={{ marginBottom: "20px" }}
            />
            <Box>
              {texts.map((_, i) => (
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  key={i}
                  onClick={() => setIndex(i)}
                  ariaLabel={`Przejdź do strony ${i}`}
                >
                  {i === index ? <CircleActive /> : <Circle />}
                </IconButton>
              ))}
            </Box>
          </Box>
          <Box className={classes.mobileImageRoot}>
            <img alt="" src={hand} className={classes.image} />
          </Box>
        </Hidden>
      </Box>
      <Box ref={scrollRef} />
    </React.Fragment>
  );
};

export default MobileApp;
