import React, { useEffect, useMemo } from "react";
import { MapContainer, Tooltip, useMapEvents } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../redux/modules/mapReducer";
import { markers, active } from "../../static/map/markers";
import { govMarkers } from "../../static/map/govMarkers";
import createClusterIcon from "./components/createClusterIcon";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MapMarker from "./components/MapMarker";
import StyledLayer from "./components/StyledLayer";
import ZoomControl from "./components/ZoomControl";
import { useHistory } from "react-router";

const BoundsController = () => {
  const dispatch = useDispatch();
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const mapZoom = useSelector(state => state.mapReducer.mapZoom);
  const history = useHistory();

  const map = useMapEvents({
    moveend() {
      const bounds = map.getBounds();
      dispatch(
        mapAction.changeBounds({
          northEast: bounds.getNorthEast(),
          southWest: bounds.getSouthWest(),
          center: map.getCenter(),
        })
      );
    },
    click(e) {
      dispatch(mapAction.setNewMarker({ pos: e.latlng }));
      history.push("/mapa/dodaj-zgloszenie");
    },
  });

  useEffect(() => {
    if (!activeMarker) {
      return;
    }
    map.flyTo([activeMarker.latitude, activeMarker.longitude], map.getZoom(), { animate: false });
  }, [activeMarker, map]);

  useEffect(() => {
    if (mapZoom === null) {
      return;
    }
    map.setZoom(mapZoom);
  }, [mapZoom, map]);

  return null;
};

const Map = ({ className }) => {
  const northEast = useSelector(state => state.mapReducer.northEast);
  const southWest = useSelector(state => state.mapReducer.southWest);
  const incidents = useSelector(state => state.mapReducer.incidents);
  const activeStatuses = useSelector(state => state.mapReducer.activeStatuses);
  const activeTypes = useSelector(state => state.mapReducer.activeTypes);
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const newMarkerPos = useSelector(state => state.mapReducer.newMarkerPos);
  const activeMarkerType = useSelector(state => state.mapReducer.activeMarkerType);
  const showFavourite = useSelector(state => state.mapReducer.showFavourite);
  const showArchive = useSelector(state => state.mapReducer.showArchive);
  const showGov = useSelector(state => state.mapReducer.showGov);
  const announcements = useSelector(state => state.mapReducer.announcements);
  const govs = useSelector(state => state.mapReducer.govs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapAction.getAnnouncements({ northEast, southWest, activeTypes }));
  }, [northEast, southWest, activeTypes, dispatch]);

  useEffect(() => {
    dispatch(
      mapAction.getIncidents({
        northEast,
        southWest,
        activeStatuses,
        showFavourite,
        showArchive,
        showGov,
      })
    );
  }, [northEast, southWest, activeStatuses, showFavourite, showArchive, showGov, dispatch]);

  const incidentsGroup = useMemo(() => {
    return (
      <MarkerClusterGroup
        showCoverageOnHover={false}
        chunkedLoading
        key={new Date().getTime() + 100}
      >
        {incidents
          .filter(i => activeMarkerType !== "incident" || i.id !== activeMarker.id)
          .map(marker => (
            <MapMarker
              key={marker.id}
              pos={[marker.latitude, marker.longitude]}
              icon={markers[marker.status]}
              marker={marker}
              type="incident"
              small
            />
          ))}
      </MarkerClusterGroup>
    );
  }, [incidents, activeMarker, activeMarkerType]);

  const announcementsGroup = useMemo(() => {
    return (
      <MarkerClusterGroup
        showCoverageOnHover={false}
        disableClusteringAtZoom={15}
        key={new Date().getTime()}
        iconCreateFunction={createClusterIcon}
      >
        {announcements
          .filter(a => activeMarkerType !== "announcement" || a.id !== activeMarker.id)
          .map(marker => (
            <MapMarker
              key={marker.id}
              marker={marker}
              pos={[marker.latitude, marker.longitude]}
              icon={govMarkers[marker.category]}
              type="announcement"
              small
            />
          ))}
      </MarkerClusterGroup>
    );
  }, [announcements, activeMarker, activeMarkerType]);

  return (
    <MapContainer
      center={[52.015, 18.123]}
      zoom={7}
      minZoom={7}
      maxZoom={18}
      className={className}
      zoomControl={false}
    >
      <StyledLayer />
      <ZoomControl />
      <BoundsController />
      {incidentsGroup}
      {announcementsGroup}
      {activeMarker && activeMarkerType === "incident" && (
        <MapMarker
          pos={[activeMarker.latitude, activeMarker.longitude]}
          icon={active[activeMarker.status]}
          type="active"
        />
      )}
      {activeMarker && activeMarkerType === "announcement" && (
        <MapMarker
          pos={[activeMarker.latitude, activeMarker.longitude]}
          icon={active.gov}
          type="active"
        />
      )}
      {newMarkerPos && (
        <MapMarker pos={newMarkerPos} icon={active.gov} type="active" key={newMarkerPos} />
      )}
      {govs.map(marker => (
        <MapMarker
          key={marker.identifier}
          pos={[marker.lat, marker.lng]}
          icon={govMarkers.GOV}
          marker={marker}
          type="gov"
        >
          <Tooltip>{marker.name}</Tooltip>
        </MapMarker>
      ))}
    </MapContainer>
  );
};

export default Map;
