import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import HeartOutlinedIcon from "../../../../../../static/map/sidebar/HeartIconOutline";
import HeartIcon from "../../../../../../static/map/sidebar/HeartIcon";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../../../services/urls";
import { markers } from "../../../../../../static/map/sidebar/markers";
import moment from "moment";
import "moment/locale/pl";
moment.locale("pl");

const useStyles = makeStyles(theme => ({
  root: {
    padding: props => (props.isTopComment ? "8px 13px 3px 30px" : "8px 13px 20px 30px"),
    borderBottom: "1px solid #DDDDDD",
  },
  avatarRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "5px",
  },
  avatar: {
    width: "24px",
    height: "24px",
    objectFit: "cover",
    objectPosition: "center",
    marginRight: "10px",
    borderRadius: "50%",
  },
  name: {
    fontSize: "13px",
    letterSpacing: "1.3px",
    color: theme.palette.text.primary,
    width: "0",
    flexGrow: "1",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  date: {
    fontSize: "10px",
    letterSpacing: "1px",
    color: theme.palette.text.secondary,
  },
  content: {
    fontSize: "13px",
    letterSpacing: "1.3px",
    color: theme.palette.text.primary,
    lineHeight: "20px",
    margin: "0 24px 0 34px",
  },
  likesRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  likeButton: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
  },
  count: {
    fontSize: "13px",
    letterSpacing: "1.3px",
    color: theme.palette.text.primary,
    "&:not(:last-of-type)": {
      marginRight: "10px",
    },
  },
  likeIcon: {
    width: "16px",
    height: "13px",
  },
  likeIconPrimary: {
    width: "16px",
    height: "13px",
    color: theme.palette.primary.main,
  },
  commentIcon: {
    width: "16px",
    height: "13px",
    objectFit: "contain",
    marginRight: "5px",
  },
}));

const Comment = ({ comment, isTopComment, isIncident, addHeart, removeHeart }) => {
  const classes = useStyles({ isTopComment });
  const createDate = moment(isIncident ? comment.createDate : comment.startDate);

  return (
    <Box className={classes.root}>
      <Box className={classes.avatarRoot}>
        <Box display="flex" alignItems="center" flexGrow="1">
          <img
            src={
              comment.avatar || comment.contactAvatarId
                ? images.avatar + `/${isIncident ? comment.avatar : comment.contactAvatarId}.jpg`
                : markers.avatar
            }
            alt={comment.nick}
            className={classes.avatar}
          />
          <Typography className={classes.name}>
            {isIncident ? comment.nick : comment.contactNick}
          </Typography>
        </Box>
        <Typography className={classes.date}>
          {createDate.isAfter(moment().subtract(2, "days"))
            ? createDate.fromNow()
            : createDate.format("DD.MM.YYYY")}
        </Typography>
      </Box>
      <Typography className={classes.content}>
        {isTopComment ? comment.description : comment.value}
      </Typography>
      {isTopComment && (
        <Box className={classes.likesRoot}>
          <IconButton
            className={classes.likeButton}
            size="small"
            onClick={() => (comment.hearted ? removeHeart() : addHeart())}
          >
            {comment.hearted ? (
              <HeartIcon className={classes.likeIconPrimary} />
            ) : (
              <HeartOutlinedIcon className={classes.likeIcon} />
            )}
          </IconButton>
          <Typography className={classes.count}>
            {comment.heartsCount ? comment.heartsCount : "0"}
          </Typography>
          {isIncident && <img src={markers.comments} className={classes.commentIcon} alt="" />}
          {isIncident && <Typography className={classes.count}>{comment.commentsCount}</Typography>}
        </Box>
      )}
    </Box>
  );
};

export default Comment;
