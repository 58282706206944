import { axios } from "../../util/axiosConfig";
import { incidents } from "../urls";

const newComment = (nick, content, image, id) => {
  const comment = {
    nick,
    value: content,
    accept: false,
  };
  const formData = new FormData();
  formData.append("comment", JSON.stringify(comment));
  if (image) {
    formData.append("file", image, image.name);
  }

  return axios
    .post(incidents.single + `/${id}/comments/new`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data);
};

export default newComment;
