import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Announcement from "./Announcement";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerRoot: {
    width: "100%",
    padding: "25px 10px",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 35px",
    },
  },
  header: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "center",
  },
  announcementsRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1",
    flexWrap: "wrap",
  },
  noAnnouncements: {
    fontSize: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
  },
}));

const Announcements = ({ announcements, isLoading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <Typography className={classes.header}>Tablica ogłoszeń</Typography>
      </Box>
      <Box className={classes.announcementsRoot}>
        {!isLoading && announcements.length === 0 ? (
          <Typography className={classes.noAnnouncements}>Brak ogłoszeń</Typography>
        ) : (
          announcements
            .slice(0, 4)
            .map(announcement => <Announcement announcement={announcement} key={announcement.id} />)
        )}
      </Box>
    </Box>
  );
};

export default Announcements;
