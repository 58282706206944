import React, { useState, useEffect } from "react";
import Root from "../components/Root";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "../components/TextField";
import Button from "@material-ui/core/Button";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import AcceptTerms from "../../../components/AcceptTerms/AcceptTerms";
import { fields } from "./fields";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import pin from "../../../static/km-logo-no-text.png";

import nickExists from "../../../services/user/nickExists";
import register from "../../../services/user/register";

const useStyles = makeStyles(theme => ({
  root: {
    display: "contents",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  headerText: {
    fontSize: "40px",
    letterSpacing: "4px",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
    }
  },
  pin: {
    height: "40px",
    marginRight: "20px",
  },
  input: {
    width: "510px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  loginButton: {
    height: "51px",
    width: "305px",
    fontSize: "25px",
    fontWeight: "500",
    borderRadius: "25.5px",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      width: "200px",
      fontSize: "16px",
      borderRadius: "18.5px",
    },
  },
  buttonRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorMessage: {
    visibility: props => (props.showMessage ? "visible" : "hidden"),
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "10px",
    fontSize: "20px",
    letterSpacing: "2px",
  },
}));

const createValues = fields => {
  const values = {};
  fields.forEach(field => {
    values[field.id] = "";
  });
  values.termsAgreement1 = "";
  values.agreement = "";
  return values;
};

const isDisabled = (values, errors) => {
  let isDisabled = false;
  Object.keys(values).forEach(key => {
    if (!isDisabled && (values[key] === "" || !values[key])) {
      isDisabled = true;
    }
  });

  Object.keys(errors).forEach(key => {
    if (!isDisabled && errors[key]) {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const RegisterPage = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [values, setValues] = useState(createValues(fields));
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({ showMessage: errorMessage });
  const history = useHistory();

  useEffect(() => {
    if (!values.nick || values.nick === "") {
      return;
    }

    nickExists(values.nick).then(exists => {
      if (exists) {
        setError("nick", "Ktoś używa tego podpisu. Wybierz inny podpis.");
      } else {
        setError("nick", null);
      }
    });
  }, [values.nick]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (key, value) => {
    const tmpValues = { ...values };
    tmpValues[key] = value;
    setValues(tmpValues);
  };

  const setError = (key, value) => {
    const tmpErrors = { ...errors };
    tmpErrors[key] = value;
    setErrors(tmpErrors);
  };

  const handleRegister = () => {
    if (values.password !== values.repeatPassword) {
      setErrorMessage("Podane hasła nie są takie same");
      return;
    } else {
      setErrorMessage(null);
    }

    setIsLoading(true);
    register(values)
      .then(response => {
        if (response.message === "OK") {
          history.push("/rejestracja/sukces");
        } else {
          setErrorMessage(response.message);
        }
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.message) {
          setErrorMessage(e.response.data.message);
        } else {
          setError("Wystpąił nieznany błąd");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Root padding="70px 0 90px">
      <form className={classes.root}>
        <Box className={classes.header}>
          <img src={pin} className={classes.pin} alt="" />
          <Typography component="h1" variant="h1" className={classes.headerText}>
            Załóż konto
          </Typography>
        </Box>
        {fields.map((field, index) => (
          <Box key={field.id} width="100%" maxWidth="510px">
            <LabelWithPin label={field.placeholder} marginBottom="8px" marginRight="8px" />
            <TextField
              className={`${classes.input} ${
                index === fields.length - 1 ? classes.extraMargin : ""
              }`}
              onChange={e => handleChange(field.id, e.target.value)}
              type={field.type}
              error={!!errors[field.id]}
              helperText={errors[field.id]}
              autoComplete={field.autocomplete}
              placeholder={field.placeholder}
              variant="filled"
              id={field.id}
            />
          </Box>
        ))}
        <Box>
          <AcceptTerms handleChange={handleChange} />
        </Box>
        <Box className={classes.buttonRoot}>
          <Typography className={classes.errorMessage}>{errorMessage + ""}</Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.loginButton}
            type="submit"
            disabled={isDisabled(values, errors) || isLoading}
            onClick={e => {
              e.preventDefault();
              handleRegister(true);
            }}
          >
            Zarejestruj się
          </Button>
        </Box>
      </form>
    </Root>
  );
};

export default RegisterPage;
