import React, { useState, useEffect, useRef } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import Box from "@material-ui/core/Box";
import { ButtonGroup, Button } from "./components/Tabs";
import MuiButton from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import NewIncident from "./components/NewIncident/NewIncident";
import AnnouncementsList from "./components/AnnouncementsList";
import IncidentsList from "./components/IncidentsList";
import ActiveMarker from "./components/ActiveMarker";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../../../redux/modules/mapReducer";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  row: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: "10px 25px",
    borderBottom: "1px solid #DDDDDD",
  },
  header: {
    fontSize: "18px",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  buttonRow: {
    height: "60px",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const START_POS = 110;

const MobileSidebar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [showCollapse, setShowCollapse] = useState(false);
  const [header, setHeader] = useState(null);
  const center = useSelector(state => state.mapReducer.center);
  const sheetRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/mapa") {
      setHeader(renderHeader());
    }
  }, [location, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () => (
    <ButtonGroup variant="text" color="primary">
      <Button color={value === 0 ? "primary" : "inherit"} onClick={() => setValue(0)}>
        Ogłoszenia
      </Button>
      <Button color={value === 1 ? "primary" : "inherit"} onClick={() => setValue(1)}>
        Zgłoszenia
      </Button>
    </ButtonGroup>
  );

  const addNewIncident = () => {
    dispatch(mapAction.setNewMarker({ pos: center }));
    history.push("/mapa/dodaj-zgloszenie");
    sheetRef.current.snapTo(({ snapPoints }) => Math.max(...snapPoints));
  };

  const openFilters = () => {
    sheetRef.current.snapTo(({ snapPoints }) => Math.max(...snapPoints));
    setShowCollapse(true);
  };

  return (
    <BottomSheet
      open
      ref={sheetRef}
      blocking={false}
      snapPoints={({ maxHeight }) => [START_POS, maxHeight - 100]}
      defaultSnap={START_POS}
      onSpringStart={() =>
        requestAnimationFrame(() => setOpen(sheetRef.current.height > START_POS))
      }
      expandOnContentDrag={!open}
      header={header}
    >
      <Switch>
        <Route path="/mapa/dodaj-zgloszenie">
          <NewIncident setHeader={setHeader} />
        </Route>
        <Route path="/mapa/:type/:id">
          <ActiveMarker setHeader={setHeader} />
        </Route>
        <Route>
          <Box className={clsx(classes.row, classes.buttonRow)}>
            <MuiButton
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => addNewIncident()}
            >
              Dodaj zgłoszenie
            </MuiButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() => (showCollapse ? setShowCollapse(false) : openFilters())}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
          {value === 0 && <AnnouncementsList showCollapse={showCollapse} />}
          {value === 1 && <IncidentsList showCollapse={showCollapse} />}
        </Route>
      </Switch>
    </BottomSheet>
  );
};

export default MobileSidebar;
