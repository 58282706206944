import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import { plPL } from "@material-ui/core/locale";

const SocialLoginButton = ({ color, ...props }) => {
  const mainTheme = useTheme();
  const theme = createMuiTheme(
    {
      typography: {
        fontFamily: "Poppins, Roboto, sans-serif",
      },
      palette: {
        primary: {
          main: color,
        },
        tonalOffset: 0.1,
      },
      overrides: {
        MuiButton: {
          contained: {
            height: "38px",
            borderRadius: "19px",
            boxShadow: "0px 3px 6px #0000003E",
            padding: "5px 22px",
            fontSize: "20px",
            fontWeight: "500",
            textTransform: "none",
            [mainTheme.breakpoints.down("sm")]: {
              fontSize: "15px",
              letterSpacing: "1.5px",
            },
            [mainTheme.breakpoints.down(400)]: {
              padding: "5px 10px",
            },
          },
          label: {
            flexGrow: "1",
          },
          startIcon: {
            width: "22px",
            height: "22px",
            marginLeft: "0",
          },
        },
      },
    },
    plPL
  );

  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" color="primary" {...props}>
        <Box flexGrow="1">{props.children}</Box>
      </Button>
    </ThemeProvider>
  );
};

export default SocialLoginButton;
