import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../../services/urls";
import moment from "moment";
import "moment/locale/pl";
moment.locale("pl");

const useStyles = makeStyles(theme => ({
  root: {
    width: "50%",
    height: "50%",
    display: "flex",
    alignItems: "center",
    padding: "10px 33px",
    borderBottom: "1px solid #DDDDDD",
    "&:nth-of-type(odd)": {
      borderRight: "1px solid #DDDDDD",
    },
  },
  innerRoot: {
    display: "flex",
    width: "100%",
  },
  image: {
    borderRadius: "10px",
    objectFit: "cover",
    objectPosition: "center",
    height: "117px",
    width: "117px",
  },
  date: {
    fontSize: "15px",
    letterSpacing: "1.5px",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  titleRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "5px",
  },
  icon: {
    marginRight: "5px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "0px",
    flexGrow: "1",
  },
  description: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    lineHeight: "20px",
    fontWeight: "300",
  },
}));

const Announcement = ({ announcement }) => {
  const createDate = moment(announcement.startDate);
  const description =
    announcement.description.length > 135
      ? announcement.description.substring(0, 135) + "..."
      : announcement.description;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.innerRoot}>
        <Box marginRight="15px">
          <img
            alt="Obrazek ogłoszenia"
            src={`${images.announcement}/${announcement.mediumImgId}.jpg`}
            className={classes.image}
          />
          <Typography className={classes.date}>
            {createDate.isAfter(moment().subtract(2, "days"))
              ? createDate.fromNow()
              : createDate.format("DD.MM.YYYY")}
          </Typography>
        </Box>
        <Box flexGrow="1">
          <Box className={classes.titleRoot}>
            <StarIcon color="primary" className={classes.icon} />
            <Typography className={classes.title}>{announcement.title}</Typography>
          </Box>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Announcement;
