import MuiTextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const FilledInput = withStyles(theme => ({
  root: {
    "& .MuiFilledInput-root": {
      height: "38px",
      borderRadius: "20px !important",
      overflow: "hidden",
    },
    "& .MuiFilledInput-input": {
      fontSize: "15px",
      padding: "0 22px",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiFilledInput-multiline": {
      padding: "5px 0",
      minHeight: "40px",
      height: "unset",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
      height: "0px",
      marginTop: "0px",
      lineHeight: "18px",
      textAlign: "center",
    },
  },
}))(MuiTextField);

export default FilledInput;
