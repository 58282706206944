import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import BluePinIcon from "../../../../static/BluePinIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../../../static/download-bg.png";
import contactBackground from "../../../../static/contact-bg.png";
import partnership from "../../../../static/partnership.png";
import { rows } from "./rows";

const useStyles = makeStyles(theme => ({
  assetsRoot: {
    height: "fit-content",
    minHeight: "calc(100vh - 78px)",
    width: "100%",
    padding: "30px min(10%, 190px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    marginBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "23px 36px",
      minHeight: "calc(100vh - 60px)",
    },
  },
  iconRoot: {
    display: "flex",
    marginBottom: "40px",
  },
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "32px",
      marginRight: "30px",
    },
  },
  header: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      fontWeight: "bold",
    },
  },
  table: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "16px",
    border: "1px solid #14387B",
    [theme.breakpoints.down("md")]: {
      borderRadius: "unset",
      border: "unset",
      background: "unset",
    },
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "120px",
    height: "fit-content",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #14387B",
    },
    [theme.breakpoints.down("md")]: {
      "&:not(:last-of-type)": {
        borderBottom: "unset",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:not(:last-of-type)": {
        marginBottom: "50px",
      },
    },
  },
  cell: {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  cellLeft: {
    width: "25%",
    borderRight: "1px solid #14387B",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
    },
  },
  cellRight: {
    width: "75%",
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "42px",
      wordBreak: "break-word",
    },
  },
  smallIcon: {
    width: "22px",
    height: "22px",
    color: theme.palette.primary.main,
    marginRight: "20px",
    marginTop: "3px",
  },
  cellTitle: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "600",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
      marginBottom: "20px",
    },
  },
  cellContent: {
    fontSize: "20px",
    letterSpacing: "2px",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  contactRoot: {
    display: "flex",
    backgroundImage: `url(${contactBackground})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundPositionY: "bottom",
    minHeight: "calc(100vh - 78px)",
    width: "calc(100% - 20px)",
    padding: "0px min(150px, 8%) 0px min(200px, 11%)",
    marginRight: "20px",
    marginBottom: "100px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      padding: "36px 16px 36px 36px",
    },
  },
  textRoot: {
    flexGrow: "1",
    display: "flex",
    marginTop: "150px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      flexGrow: "unset",
    },
  },
  contactIcon: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "11px",
    },
  },
  contactHeader: {
    maxWidth: "700px",
    marginBottom: "41.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  imageRoot: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  image: {
    marginTop: "80px",
    width: "516px",
    height: "516px",
    borderRadius: "50%",
    boxShadow: "0px 6px 6px #00000045",
  },
  text: {
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "46px",
    maxWidth: "795px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  link: {
    display: "block",
    fontWeight: "600",
    wordBreak: "break-word",
  },
}));

const Assets = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <React.Fragment>
      <Box className={classes.assetsRoot}>
        <Box className={classes.iconRoot}>
          <BluePinIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.header}>
            {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} oferuje Twojemu urzędowi:
          </Typography>
        </Box>
        <Box className={classes.table}>
          {rows.map((row, index) => (
            <Box className={classes.row} key={index}>
              <Box className={`${classes.cell} ${classes.cellLeft}`}>
                <Box display="flex" width="100%">
                  <CheckCircleIcon className={classes.smallIcon} />
                  <Typography className={classes.cellTitle}>{row.title}</Typography>
                </Box>
              </Box>
              <Box className={`${classes.cell} ${classes.cellRight}`}>
                <Typography className={classes.cellContent}>{row.content}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.contactRoot}>
        <Box className={classes.textRoot}>
          <BluePinIcon className={`${classes.icon} ${classes.contactIcon}`} />
          <Box>
            <Typography component="h2" variant="h2" className={classes.contactHeader}>
              Chcesz dowiedzieć się więcej? Skontaktuj się z nami!
            </Typography>
            <Typography className={classes.text}>
              Z przyjemnością zaprezentuję całe rozwiązanie i przedstawię więcej szczegółów.
            </Typography>
            <Typography className={classes.text}>Bożena Bulik</Typography>
            <Link className={`${classes.text} ${classes.link}`} href="tel:795 524 038">
              +48 795 524 038
            </Link>
            <Link
              className={`${classes.text} ${classes.link}`}
              href={`mailto:bozena.bulik@${isLocalSpot ? "localspot" : "komunikatormiejski"}.pl`}
            >
              bozena.bulik@{isLocalSpot ? "localspot" : "komunikatormiejski"}.pl
            </Link>
          </Box>
        </Box>
        <Hidden smDown>
          <Box className={classes.imageRoot}>
            <img src={partnership} alt="" className={classes.image} />
          </Box>
        </Hidden>
      </Box>
    </React.Fragment>
  );
};

export default Assets;
