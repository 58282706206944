import React, { useEffect, useState } from "react";
import TextField from "../../../../../../components/FilledInput/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../../../../../redux/modules/mapReducer";

const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
  },
  text: {
    fontSize: "15px",
    fontWeight: "500",
  },
}));

const CategorySelect = ({ handleChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const categories = useSelector(state => state.mapReducer.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(mapAction.searchCategories());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateValue = value => {
    handleChange("category", value);
    setValue(value);
  };

  const selectProps = {
    displayEmpty: true,
    renderValue: value =>
      value === "" ? (
        <Typography color="textSecondary" className={classes.text}>
          Wybierz kategorię
        </Typography>
      ) : (
        <Typography className={classes.text}>
          {categories.find(c => c.id === value).value}
        </Typography>
      ),
  };

  return (
    <TextField
      select
      className={classes.input}
      value={value}
      onChange={e => updateValue(e.target.value)}
      SelectProps={selectProps}
      variant="filled"
    >
      {categories.map(category => (
        <MenuItem value={category.id} key={category.id}>{category.value}</MenuItem>
      ))}
    </TextField>
  );
};

export default CategorySelect;
